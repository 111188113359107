import FeedEventsHandlerMixin from '../utils/mixins/FeedEventsHandlerMixin';

export default {
  mixins: [FeedEventsHandlerMixin],

  feedHandlers: [
    {
      event: 'chat:update',
      handler(event) {
        this.setChat(event.body);
      }
    },
    {
      event: 'chat:create',
      handler(event) {
        this.setChat(event.body);
      }
    },
    {
      event: 'author:update',
      handler(event) {
        this.setAuthor(event.body);
      }
    }
  ],

  data() {
    return {
      feed: null,
      chats: {},
      authors: {}
    };
  },

  methods: {
    bind(feed) {
      this.feed = feed;
    },

    unbind() {
      this.feed = null;
    },

    setChat(chat) {
      this.chats[chat.id] = chat;

      Object.values(chat.authors).forEach(this.setAuthor);
      this.reassign();
    },

    setAuthor(author) {
      this.authors[author.id] = author;

      Object.values(this.chats).forEach(chat => {
        if (chat.authors[author.id]) {
          chat.authors[author.id] = author;
        }
      });
      this.reassign();
    },

    reassign() {
      // FXME: This thing exists because of vue does not detects
      // changes inside the objects.
      this.chats = Object.assign({}, this.chats);
      this.authors = Object.assign({}, this.authors);
    }
  }
};
