import { createHOCc } from 'vue-hoc';

import FeedTypingHandlerMixin from '../mixins/FeedTypingHandler';
import MessagingInjectorMixin from '../mixins/MessagingInjectorMixin';

export default createHOCc({
  mixins: [FeedTypingHandlerMixin, MessagingInjectorMixin],

  props: {
    chat: Object,
    userId: [Number, String],
  }
}, {
  props: {
    chat() { return this.chat; },
    userId() { return this.userId; },
    typing() { return this.typingAuthors; }
  }
});
