import { path, clone, defaultTo, pipe, assocPath } from 'ramda'

const ad = defaultTo([])
const od = defaultTo({})
const pathDisplayName = ['profile', 'displayName']
const pathAddress = ['profile', 'data', 'address']
const pathEmail = ['email']
const pathPhones = ['profile', 'data', 'phones']

const pathFaxes = ['profile', 'data', 'faxes']
const pathFilters = ['profile', 'data', 'filters']
const pathLocation = ['profile', 'location']
const pathNewsSubscription = ['profile', 'isNewsSubscription']
const pathSubscriptions = ['profile', 'subscribeSpecializations']


const getDisplayName = path(pathDisplayName)
const getAddress = pipe(path(pathAddress), od, clone)
const getEmail = path(pathEmail)
const getPhones = pipe(path(pathPhones), ad, clone)

const getFaxes = pipe(path(pathFaxes), ad, clone)
const getFilters = pipe(path(pathFilters), ad, clone)
const getLocation = path(pathLocation)
const getNewsSubscription = path(pathNewsSubscription)
const getSubscriptions = path(pathSubscriptions)


const assocDisplayName = assocPath(pathDisplayName)
const assocAddress = assocPath(pathAddress)
const assocEmail = assocPath(pathEmail)
const assocPhones = assocPath(pathPhones)

const assocFaxes = assocPath(pathFaxes)
const assocFilters = assocPath(pathFilters)
const assocLocation = assocPath(pathLocation)
const assocNewsSubscription = assocPath(pathNewsSubscription)
const assocSubscriptions = assocPath(pathSubscriptions)

const mapDataAddressToLocation = (object, { point } = { point: {} }) => {
  if (point) {
    object = {
      latitude: point.lat,
      longitude: point.lng
    }
  }
  return object
}

export function profileTo(user) {
  return {
    displayName: getDisplayName(user),
    address: getAddress(user),
    email: getEmail(user),
    phones: getPhones(user).length ? getPhones(user) : [''],
    faxes: getFaxes(user).length ? getFaxes(user) : [''],
    filters: getFilters(user) ? getFilters(user) : {},
    location: getLocation(user) || {},
    isNewsSubscription: getNewsSubscription(user) || false,
    subscribeSpecializations: getSubscriptions(user) || [],

  }
}

export function profileFrom(data, user) {
  user = assocDisplayName(data.displayName, user)
  user = assocAddress(data.address, user)
  user = assocEmail(data.email, user)
  user = assocPhones(data.phones, user)

  user = assocFaxes(data.faxes, user)
  user = assocFilters(data.filters, user)
  user = assocLocation(mapDataAddressToLocation(data.location, data.address), user)
  user = assocNewsSubscription(data.isNewsSubscription, user)
  user = assocSubscriptions(data.subscribeSpecializations, user)

  return user
}


export function arrayToIds (array) {
  return [...array].map(item => item && item.id ? item.id : item)
}