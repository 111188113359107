import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=7c0b74a5&functional=true&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports