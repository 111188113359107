<template lang="pug">

include /mixins.pug

validation-observer(v-slot="observer", ref="validator", slim)
  slot(
    :observer="observer",
    :submit="createSubmit(observer)",
    :reset="reset",
    :createSubmit="createSubmit",
    :send="send",
    :log="log",
    :options="options",
    :submitting="submitting",
    :data="data",
    :result="result",
    :globalErrors="nonFieldErrors",
    :setCaptcha="setCaptcha"
  )

</template>

<script>

import { clone } from 'ramda';
import { GenericFormMixin } from '@aspectus/vue-forms';

export default {
  name: 'simple-form',
  mixins: [GenericFormMixin],
  props: {
    watchInitial: Boolean,
    initial: null,
    options: {
      default: () => ({}),
    },
    sendResource: null,
  },

  data() {
    this.captcha = null;
    return {
      result: null,
    };
  },

  watch: {
    initial: {
      immediate: true,
      handler(value, old) {
        if (!this.watchInitial && !(old === null || typeof old === 'undefined')) {
          return;
        }

        this.initializeData(value);
      },
    },
  },

  methods: {
    setCaptcha(captcha) {
      this.captcha = captcha;
      this.callCaptcha('execute');
    },
    callCaptcha(method) {
      const captcha = this.captcha || this.$refs.invisibleRecaptcha || this.$parent.$refs.invisibleRecaptcha;
      captcha && captcha[method]();
    },
    initializeData(value = this.initial) {
      this.data = this.transformTo(clone(value));
    },
    createSubmit(observer) {
      return (fields, additionals) => observer.handleSubmit(() => this.submit(fields, additionals));
    },
    send(data) {
      const transformed = this.transformFrom(data);
      
      return this.sendResource.execute(this.getSendParameters(transformed), transformed)
        .then(this.finish)
        .catch(this.fail)
        .finally(r => {
          this.callCaptcha('reset');
          this.callCaptcha('execute');
          return r;
        });
    },
    fail(e) {
      console.error(e)
      this.$emit('fail', { context: this })
      throw e;
    },
    log() {
      console.log.apply(console, arguments);
    },
    finish(result) {
      this.result = result;
      this.$emit('finish', { result, context: this });
      this.data.editing = false;
    },
    reset() {
      this.resetValidator();
      this.initializeData();
      this.result = null;
    },
  },
};

</script>
