import ProfileEmployerForm from './ProfileEmployerForm';
import ProfileEmployeeForm from './ProfileEmployeeForm';
import ProfileUploaderForm from './ProfileUploaderForm';

import ModalOpener from './ModalOpener';
import UserFilter from './UserFilter';

export function install(Vue) {
  Vue.component(ProfileEmployerForm.name, ProfileEmployerForm);
  Vue.component(ProfileEmployeeForm.name, ProfileEmployeeForm);
  Vue.component(ProfileUploaderForm.name, ProfileUploaderForm);
  
  Vue.component(ModalOpener.name, ModalOpener);
  Vue.component(UserFilter.name, UserFilter);
}

export default { install };
