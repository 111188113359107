const messages = {
  [window.language]: window.django.catalog,
};

const NUMERIC = 'numeric';
const LONG = 'long';
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
};
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit',
};
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
};
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
};

const dateTimeFormats = {
  [window.language]: {
    shortDate: SHORT_DATE_CONFIG,
    simpleDate: DATE_CONFIG,
    simpleTime: TIME_CONFIG,
    simpleMonth: { year: NUMERIC, month: LONG },
    datetime: DATETIME_CONFIG,
  },
};

function translator() {
  return this.$t.apply(this, arguments);
};

export function createI18n({ Vue, I18n }) {
  Vue.prototype._ = translator;

  const i18n = new I18n({
    locale: window.language,
    silentTranslationWarn: process.env.NODE_ENV !== 'production',
    dateTimeFormats,
    messages,
  });

  return i18n;
}
