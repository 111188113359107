import BemComponentMixin from '../../../utils/bem/BemComponentMixin';

/**
 * Progress component.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'ProgressBar',
  mixins: [BemComponentMixin],

  bem: {
    block: 'c-progress',
    modifierProps: ['type']
  },

  props: {
    /**
     * Progress type name.
     */
    type: {
      type: String,
      default: null
    },
    /**
     * Progress bars values.
     */
    bars: {
      type: Array,
      default: null
    }
  },

  methods: {
    barStyle(width) {
      return {
        width: `${width * 100}%`
      }
    }
  }
};
