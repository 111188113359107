import { mergeContext } from '@aspectus/vue-utils';
import { getLabel, normalizeSelectValue } from './Select';

function getKey(option, track) {
  return track ? option[track] : option;
}

function isSelected(context, option) {
  const value = normalizeSelectValue(context.props.value || context.data.attrs.value);
  const trackBy = context.props.trackBy || context.data.attrs.trackBy;
  const key = getKey(option, trackBy);

  return value.some(x => key === getKey(x, trackBy));
}

export function checkerOption(h, context, props) {
  const label = context.scopedSlots.label
    ? context.scopedSlots.label(props)
    : getLabel(
      props.option,
      context.props.label,
      context.data.attrs.customLabel
    );
  return h('row', null, [
    h('cell', { attrs: { cols: 'narrow' } }, [
      h('control-checker', {
        attrs: {
          b: context.props.multiple ? 'control-checkbox' : 'control-radio',
          tag: 'div',
          checked: isSelected(context, props.option),
        },
      }),
    ]),
    h('cell', { attrs: { cols: 'auto' } }, [label]),
  ]);
}

export default {
  name: 'control-checker-select',
  functional: true,

  props: {
    label: {},
    trackBy: {},
    value: {},
    multiple: { type: Boolean, default: false },
  },

  render(h, context) {
    const {
      trackBy,
      label,
      value,
      multiple,
    } = context.props;

    return h(
      'control-select',
      mergeContext(context.data, {
        attrs: { trackBy, label, value, multiple },
        scopedSlots: {
          ...context.data.scopedSlots,
          option: props => checkerOption(h, context, props),
        },
      }),
      context.children
    );
  },
};
