import JobsFilter from './JobsFilter';
import JobsForm from './JobsForm';
import JobsResponder from './JobsResponder';

function install(Vue) {
  Vue.component(JobsFilter.name, JobsFilter);
  Vue.component(JobsForm.name, JobsForm);
  Vue.component(JobsResponder.name, JobsResponder);
}

export default { install };
