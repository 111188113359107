<template lang="pug">

include /mixins.pug

row(appearance="spaced", space="md", v-if="form.globalErrors && form.globalErrors.length")
  cell(cols="12", v-for="(error, i) in form.globalErrors", :key="i")
    +b.control-hint.--styling_default.--variant_error {{ error }}

</template>

<script>

export default {
  name: 'global-form-errors',
  props: {
    form: {},
  },
};

</script>
