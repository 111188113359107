import { formatTranslation } from '@md/strings'

export const normalizeDate = date => (
  date instanceof Date && !isNaN(date) && date || new Date()
)
export const parseDate = date => normalizeDate(new Date(date))
export const integer = value => (+value | 0)

export const install = Vue => {
  Vue.filter('normalizeDate', normalizeDate)
  Vue.filter('parseDate', parseDate)
  Vue.filter('integer', integer)
  Vue.filter('format', formatTranslation)
  Vue.filter('formatDate', (date) => {
    return date && date.toLocaleString('ru-Ru', {
      hour: 'numeric',
      minute: 'numeric'
    })
  })
  Vue.filter('localizeDate', value => {
    console.log(value)
    if (window.document.documentElement.lang === 'en') {
      const [month, date, year] = value.split('/')
      value = `${date}/${month}/${year}`
    }
    return value.replace(/\//g, '.')
  })
}

export default { install }
