import { renderSlim, mergeContext, isEmpty } from '@aspectus/vue-utils';

export default {
  name: 'optional-validation-provider',
  functional: true,

  props: {
    rules: {},
  },

  render(h, context) {
    const { rules } = context.props;

    if (isEmpty(rules)) {
      return renderSlim(context.scopedSlots.default(), h);
    }

    return h(
      'validation-provider',
      mergeContext(context.data, { props: { rules } }),
      context.children
    );
  },
};
