import { TypeHandler } from '../type-handler';
import FeedEventsHandlerMixin from './FeedEventsHandlerMixin';

/**
 * @mixin
 */
export default {
  mixins: [FeedEventsHandlerMixin],

  feedHandlers: [
    {
      event: 'typing:start',
      handler(event) {
        if (
          event.body.chatId === this.chat.id &&
          event.body.authorId !== this.userId
        ) {
          this.typer.emitter.emit('typing:start', event.body.authorId);
        }
      }
    },

    {
      event: 'typing:stop',
      handler(event) {
        if (
          event.body.chatId === this.chat.id &&
          event.body.authorId !== this.userId
        ) {
          this.typer.emitter.emit('typing:stop', event.body.authorId);
        }
      }
    }
  ],

  typerOptions: { startTimeout: 800 },

  data() {
    return {
      typer: new TypeHandler(this.$options.typerOptions).bind()
    };
  },

  computed: {
    typingAuthors() {
      return !this.chat ? [] : this.typer.typing.map(x => this.chat.authors[x]).filter(x => !!x);
    }
  }
};
