const PAGE_SHIFT = 1;

export function paginatorFactory(PaginatorClass, parametersGetter) {
  return function getter(data) {
    return new PaginatorClass(parametersGetter(data));
  }
}

export class Paginator {
  constructor(info = {}) {
    this.info = info;
  }

  hasNext() {
    throw new Error('Implement `hasNext` method in a subclass.');
  }

  hasPrevious() {
    throw new Error('Implement `hasPrevious` method in a subclass.');
  }

  next() {
    throw new Error('Implement `next` method in a subclass.');
  }

  previous() {
    throw new Error('Implement `previous` method in a subclass.');
  }
}

export class LimitPaginator extends Paginator {
  limitDefault = 50;
  limitMax = Infinity;

  constructor(info) {
    super(info);

    this.limit = info.limit || this.limitDefault;

    if (this.limit > this.limitMax) {
      throw new Error('Limit amount is exceeds limit maximum.');
    }

    if (this.limit < 1) {
      throw new Error('Limit amount cant\'t be less that 1.');
    }
  }
}

/**
 * A cursor pagination based style. For example:
 *
 * http://api.example.org/accounts/?limit=100
 * http://api.example.org/accounts/?startCursor=eyJzb21lIjoidGhpbmcifQ==&limit=100
 *
 * @export
 * @class CursorPaginator
 * @extends {LimitPaginator}
 */
export class CursorPaginator extends LimitPaginator {
  hasNext() {
    return this.info.hasNext;
  }

  hasPrevious() {
    return this.info.hasPrevious;
  }

  next() {
    return {
      after: this.info.endCursor,
      limit: this.limit
    }
  }

  previous() {
    return {
      before: this.info.startCursor,
      limit: this.limit
    }
  }
}

/**
 * A limit/offset pagination based style. For example:
 *
 * http://api.example.org/accounts/?limit=100
 * http://api.example.org/accounts/?offset=400&limit=100
 *
 * @export
 * @class LimitOffsetPaginator
 * @extends {LimitPaginator}
 */
export class LimitOffsetPaginator extends LimitPaginator {
  constructor(info, limit = null) {
    super(info);

    this.limit = limit || this.limit;
    this.count = this.info.count;
    this.offset = this.info.offset;
  }

  hasNext() {
    return this.offset + this.limit < this.count;
  }

  hasPrevious() {
    return this.offset > 0;
  }

  next() {
    if (!this.hasNext()) {
      throw new Error('Before getting `next` check for a page existance.');
    }

    return this.getParameters(this.getPageNumber() + 1);
  }

  previous() {
    if (!this.hasPrevious()) {
      throw new Error('Before getting `previous` check for a page existance.');
    }

    return this.getParameters(this.getPageNumber() - 1);
  }

  getPageNumber(offset = this.offset, shift = PAGE_SHIFT) {
    return Math.floor(offset / this.limit) + shift;
  }

  getPageRange(distance = null, current = this.getPageNumber()) {
    const min = 1;
    const max = Math.max(min, Math.ceil(this.count / this.limit));

    if (distance === null || distance === Infinity) {
      return [min, max];
    }

    return [
      Math.max(min, current - distance),
      Math.min(max, current + distance)
    ];
  }

  getParameters(number = null) {
    let page = 1;

    if (number !== null) {
      const [min, max] = this.getPageRange(Infinity, number);

      page = Math.min(max, Math.max(min, number));
    } else {
      page = this.getPageNumber();
    }

    return {
      offset: this.limit * (page - PAGE_SHIFT),
      limit: this.limit
    };
  }
}
