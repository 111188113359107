import { generateMonth } from '@md/ui/components/controllable-table/utils';

export const ar = v => (Array.isArray(v) ? v : []);
export const cmp = (a, b) => (
  (a.activity.order_position || 0) -
  (b.activity.order_position || 0)
);

export default {
  name: 'tables-timing-controller',
  props: {
    value: {},
    month: {},
    options: {
      type: Array,
      default: () => [],
    },
    worktimeStarts: {},
    worktimeEnds: {},
  },
  render(h) {
    return h(null);
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        const activityIds = ar(this.value).map(x => x.activity.id);
        const unfilled = this.options.filter(x => !activityIds.includes(x.id));

        if (unfilled && unfilled.length) {
          this.update(unfilled);
        }
      },
    },
  },
  methods: {
    update(activities) {
      const date = new Date(this.month);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      this.input(ar(this.value).concat(activities.map(activity => ({
        activity, table: generateMonth(month, year, { startTime: this.worktimeStarts, endTime: this.worktimeEnds }), total: 0,
      }))).sort(cmp));
    },
    input(value) {
      this.$emit('input', value);
    },
  },
};
