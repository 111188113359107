import { path, clone, defaultTo, pipe, assocPath } from 'ramda'
import SimpleForm from '@md/ui/components/SimpleForm.vue'

import { profileTo, profileFrom, arrayToIds } from './utils'

const od = defaultTo({})
const pathContactName = ['profile', 'data', 'contactName']
const pathBank = ['profile', 'data', 'bank']
const getContactName = path(pathContactName)
const getBank = pipe(path(pathBank), od, clone)
const assocContactName = assocPath(pathContactName)
const assocBank = assocPath(pathBank)



export default {
  extends: SimpleForm,
  name: 'profile-employer-form',

  methods: {
    createSubmit(observer) {
      return (fields, additionals) => {
        this.USER.SET_SETTINGS_INVALID(observer.dirty && observer.invalid)
        return observer.handleSubmit(() => this.submit(fields, additionals))
      }
    },
    setErrors(observer = this.$refs.validator) {
      console.log({ ...observer.flags })
      this['nonFieldErrors'] = observer.flags.invalid ? [this._('Пожалуйста, заполните обязательные поля чтобы активировать профиль')] : []
      console.log(this['nonFieldErrors'])
    },
    transformTo(user) {
      return Object.assign(profileTo(user), {
        contactName: getContactName(user),
        bank: getBank(user),
      })
    },
    transformFrom(data) {
      let user = profileFrom(data, clone(this.initial))
      user.profile.subscribeSpecializations = arrayToIds(user.profile.subscribeSpecializations)
      user = assocContactName(data.contactName, user)
      user = assocBank(data.bank, user)

      return user
    },
  },
  mounted() {
    setTimeout(() => {
      this.setErrors()
    }, 100)
  },
}
