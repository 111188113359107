<template functional lang="pug">

include /mixins.pug

+b.TAG.control-badge(
  :class="[data.staticClass, data.class]"
  v-bem:control-badge="props"
  v-bind="data.attrs"
  v-on="data.on"
)
  +e.element.--primary
    slot

</template>

<script>

export default {
  name: 'control-badge',
  props: {
    size: { default: 'md' },
    styling: { default: 'primary' },
    variant: { default: 'default' },
    appearance: {},
  },
};

</script>
