import { createBlockGenerator } from '@aspectus/bem';
import { mergeContext } from '@aspectus/vue-utils';

const block = createBlockGenerator()('svg-icon');

export default {
  name: 'svg-icon',
  functional: true,
  props: ['name', 'appearance', 'variant', 'theme', 'view', 'type'],
  render(h, context) {
    let iconModule = null;
    try {
      iconModule = require(`@/assets/icons/${context.props.name}.i.svg`);
    } catch (e) {
      console.warn(e);
    }
    const SVG = iconModule;

    return h(
      'ds-inliner',
      mergeContext(context.data, {
        class: block(context.props),
      }),
      [
        SVG && h(
          SVG, { class: block('element', { name: context.props.name }) }
        ) || null,
      ]
    );
  },
};
