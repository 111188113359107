import { curry } from 'ramda';
import { jsonRequestMiddleware } from '@aspectus/resource';
import { baseResource, createReceiver } from '@md/resources';
import makeTemplateGetter from '@aspectus/resource-template-url-getter';
import { prefixAPI } from '@md/api';

const filtersGetterBase = makeTemplateGetter('{;filters*}');
const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null;
const partGetter = (name, prefix) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`);
  return parameters => {
    const result = base(parameters);
    return result === prefix ? null : result;
  };
};
const categoryGetter = partGetter('category', 'category');
const pageGetter = partGetter('page', 'page');
const getPrametersGetter = makeTemplateGetter('{?order_by}');

const JOBS_PREFIX = '/jobs/';
const JOBS_ITEMS_PREFIX = JOBS_PREFIX + 'items/';

const jobsUrlGetter = curry((prefix, p) => {
  const result = [
    categoryGetter(p), filtersGetter(p), pageGetter(p),
  ];
  return (prefix + result.filter(x => x !== null).join('/') + '/').replace(/\/\//img, '/') + getPrametersGetter(p);
});

export const jobsListUrlGetter = jobsUrlGetter(JOBS_PREFIX);
export const jobsItemsUrlGetter = jobsUrlGetter(JOBS_ITEMS_PREFIX);

export const jobsItemsResource = baseResource.url(jobsItemsUrlGetter);

export const jobsCreateResource = createReceiver(prefixAPI('/recruit/jobs/create/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'POST');
export const jobsUpdateResource = createReceiver(prefixAPI('/recruit/jobs{/id}/update/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'PUT');


export const bidsCreateResource = createReceiver(prefixAPI('/recruit/bids/create/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'POST');
