import { createHOC } from 'vue-hoc';

import FileUploaderWrapper from '../wrappers/FileUploaderWrapper';

export default function hoc(Component) {
  return createHOC(FileUploaderWrapper, null, {
    props: {
      wrappedComponent: Component
    }
  });
}
