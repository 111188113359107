import '@md/vendor/promise';

import VueI18n from 'vue-i18n';
// import Vuex from 'vuex';

import VueMultiselect from 'vue-multiselect';
import VueObserveVisibility from 'vue-observe-visibility';
import ClickOutside from 'vue-click-outside';
// import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/default.css';
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import VueSlickCarousel from 'vue-slick-carousel';
// import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueToast from 'vue-toast-notification';
import VModal from 'vue-js-modal';
import VueMask from 'vue-the-mask';
import VueSelectionController from '@aspectus/vue-selection-controller';
import ChatProvider from '@md/chat/ChatProvider';
import VueSocialSharing from 'vue-social-sharing';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';
import createPreviewDirective from 'vue-photoswipe-directive';
import VueRecaptcha from 'vue-recaptcha';


import VueTag from '@aspectus/vue-tag';
import VueGrid from '@aspectus/vue-grid';
import VueBem from '@aspectus/vue-bem';
import VueDermis from '@aspectus/vue-dermis';
import VueDropzone from '@aspectus/vue-dropzone';
import VueDropzoneUploader from '@aspectus/vue-dropzone-uploader';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import UI from '@md/ui';
import Filters from '@md/filters';
import Resources from '@md/resources';

import Users from '@md/users';
import Jobs from '@md/jobs';
import Tables from '@md/tables';

import DateFormat from '@md/chat/components/DateFormat'
import Avatar from '@md/chat/components/Avatar/Avatar'
import { Provider, Room, Conversation, Preview, MessagesGroup, Message, List, Audio } from '@md/chat'
import Launcher from '@md/chat/components/Launcher';

import Api from './api';

require('vue2-datepicker/locale/de');

export function install(Vue) {
  Vue.directive('img-preview', createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI));
  Vue.component('DateFormat', DateFormat)
  Vue.component('chat-feed-provider', Provider)
  Vue.component('chat-launcher', Launcher)
  Vue.component('Avatar', Avatar)
  Vue.component('Room', Room)
  Vue.component('Conversation', Conversation)
  Vue.component('Preview', Preview)
  Vue.component('MessagesGroup', MessagesGroup)
  Vue.component('Message', Message)
  Vue.component('List', List)
  Vue.component('Audio', Audio)

  Vue.use(VueI18n);
  // Vue.use(Vuex);

  Vue.component('vue-multiselect', VueMultiselect);
  Vue.use(VueObserveVisibility);
  Vue.directive('click-outside', ClickOutside);
  // Vue.component('vue-slick-carousel', VueSlickCarousel);
  // Vue.component('vue-slider', VueSlider);
  Vue.component('vue-recaptcha', VueRecaptcha);
  Vue.component('vue-date-picker', VueDatePicker);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);
  Vue.use(VueToast);
  Vue.use(VModal, { dynamic: true, dialog: true });
  Vue.use(VueMask);
  Vue.component('chat-provider', ChatProvider);

  Vue.use(VueTag);
  Vue.use(VueBem);
  Vue.use(VueGrid);
  Vue.use(VueDermis);
  Vue.use(VueDropzone);
  Vue.use(VueDropzoneUploader, {
    name: 'dropzone-uploader',
  });
  Vue.use(VueSocialSharing);


  Vue.use(UI);
  Vue.use(Filters);
  Vue.use(Resources);

  Vue.use(Users);
  Vue.use(Jobs);
  Vue.use(Tables);
  Vue.use(Api);

  Vue.use(VueFlatPickr);

  Vue.use(VueSelectionController);

  // Confirm dialog
  Vue.prototype.$confirm = function confirm(value, actor, text, cancelHandler = () => {}) {
    this.$modal.show('dialog', {
      text,
      buttons: [
        { title: this._('No'), default: true, handler: () => { this.$modal.hide('dialog'); cancelHandler(value); } },
        { title: this._('Yes'), handler: () => { this.$modal.hide('dialog'); actor(value); } },
      ],
    });
  };
  Vue.prototype.$confirmLink = function (link, text) {
    return this.$confirm(null, () => this.$GO(link), text);
  };
}

export default { install };
