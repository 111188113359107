export default {
  sendResource: null,

  methods: {
    finish() {},

    transformFrom(data) {
      return data;
    },

    transformTo(data) {
      return data;
    },

    getSendParameters(data) {
      return data;
    },

    send(data) {
      const transformed = this.transformFrom(data);

      return this.$options.sendResource.execute(this.getSendParameters(transformed), transformed)
        .then(this.finish);
    },
  },
};
