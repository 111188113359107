import { renderSlim } from '@aspectus/vue-utils';
import LoadingState from '@md/ui/mixins/LoadingState';
import { prefixAPI } from '@md/api';

const Modal = () => import('./TableCreateModal');
const idGetter = x => x.id;

export default {
  name: 'tables-list-controller',
  mixins: [LoadingState],
  props: {
    value: Array,
    tables: {
      type: Array,
      default: () => [],
    },
    initial: Object,
    keyGetter: {
      type: Function,
      default: idGetter,
    },
    removeResource: Object,
  },
  render(h) {
    return renderSlim(this.$scopedSlots.default({
      remove: this.remove,
      add: this.add,
      value: this.value,
      initial: this.initial,
      create: this.create,
      loading: this.loading,
      makeDownloadLink: this.makeDownloadLink,
      makeEditLink: this.makeEditLink,
    }), h);
  },
  methods: {
    remove(table) {
      this.$load(this.removeResource.execute({ id: table.id }))
        .catch(() => {
          const key = this.keyGetter(table);
          const index = this.value.findIndex(x => this.keyGetter(x) === key);

          if (index !== -1) {
            this.input(this.value.slice(0, index).concat(this.value.slice(index + 1)));
          }
        });
    },
    makeDownloadLink(table, format) {
      return prefixAPI(`/recruit/tables/${this.keyGetter(table)}/download/${format}/`);
    },
    makeEditLink(table) {
      return `/cabinet/tables/${this.keyGetter(table)}/change/`;
    },
    add(table) {
      console.log()
      this.input(this.value.concat([table]));
      this.$GO(this.makeEditLink(table))
    },
    input(value) {
      this.$emit('input', value);
    },
    create() {
      this.$modal.show(
        Modal,
        { initial: this.initial, onInput: this.add },
        { adaptive: true, scrollable: true, height: 'auto', width: '100%' }
      );
    },
  },
};
