import partial from 'ramda/src/partial';

import BemComponentMixin from '@md/utils/bem/BemComponentMixin';
import { FileDescriptor } from '../../file';

/**
 * File upload handler component.
 *
 * @version 0.1.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'FileUpload',
  mixins: [BemComponentMixin],

  bem: {
    block: 'c-file-upload'
  },

  props: {
    /**
     * Upload handler function.
     * Function should receive file object as a parameter, and as a
     * result - return an object with:
     *
     *  - emitter - Event emitter, that emits error, success and
     *    progress events.
     *  - cancel - function to call, when user want's to stop uploading,
     *    or remove already uploaded file.
     */
    handler: {
      type: Function
    },

    /**
     * Determines whether to start uploading process on file dropping.
     */
    autoload: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      files: []
    }
  },

  methods: {
    /**
     * Opens dialog window to select files to add.
     *
     * @public
     */
    open() {
      this.$refs.trigger.open();
    },

    process() {
      const event = {
        isReady: this.files.every(file => file.isReady),
        files: this.files
      };

      /**
      * File uploading process event. Fires only on file readyness change.
      *
      * Properties:
      *
      *  - isReady - Boolean, that describes file readyness.
      *  - files - All files, that are now is in process.
      *
      * @event process
      * @type {object}
      */
      this.$emit('process', event);

      if (event.isReady) {
        /**
        * Event, that fires on files uploading finished.
        *
        * Properties:
        *
        *  - isReady - Boolean, that describes file readyness.
        *  - files - All files, that are now is in process.
        *
        * @event finish
        * @type {object}
        */
        this.$emit('finish', event);
      }
    },

    /**
     * Handles drop event from <file-update-trigger /> component.
     *
     * @param {*} event Files drop event, that has `.files` property.
     */
    drop(event) {
      let { files } = event;
      files = files.map(file => new FileDescriptor(file));

      this.files = files.concat(this.files);

      if (this.autoload) {
        files.forEach(file => {
          this.upload(file);
        });
      } else {
        // Because `.process` is calling each time file starts to upload.
        this.$emit('drop', files);
        this.process();
      }
    },

    /**
     * Starts file uploading process.
     *
     * @param {*} file File object, that has to be uploaded.
     * @public
     */
    upload(file) {
      if (!file.isLoaded) {
        file.upload(this.handler);
        file.emitter.on('ready', () => this.ready(file));

        /**
        * Event, that fires on file uploading started.
        *
        * @event upload
        * @type {file} File object.
        */
        this.$emit('upload', file);
        this.process();
      }
    },

    ready(file) {
      /**
      * Event, that fires on file uploading success.
      *
      * @event success
      * @type {file} File object.
      */
      /**
      * Event, that fires on file uploading failed.
      *
      * @event failed
      * @type {file} File object.
      */
      const event = file.info.status === 'success' ? 'success' : 'error'

      this.$emit(event, file);
      this.process();
    },

    /**
     * Removes file from list of upload. Also calls cancellation method
     * of file object.
     *
     * @param {*} file File object, that has to be removed.
     * @param boolean toDelete Determines whether file must be removed
     *  from server.
     * @public
     */
    remove(file, toDelete = false) {
      const index = this.files.indexOf(file);

      file[['cancel', 'delete'][toDelete ? 1 : 0]]();

      if (index != -1) {
        this.files.splice(index, 1);
      }

      /**
      * Event, that fires on file being removed.
      *
      * @event remove
      * @type {file} File object.
      */
      this.$emit('remove', file, toDelete);
      this.process();
    }
  }
};
