import { renderSlim } from '@aspectus/vue-utils';

const Modal = () => import('./JobsRespondModal');

export default {
  name: 'job-responder',
  props: {
    id: Number,
    title: String,
  },
  render(h) {
    if (!this.USER.IS_UNLOCKED || !this.USER.IS_EMPLOYEE || this.RESPONDEND_JOBS.HAS(this.id)) {
      return null;
    }

    return renderSlim(this.$scopedSlots.default({ respond: this.respond }), h);
  },
  methods: {
    respond() {
      this.$modal.show(Modal, { id: this.id, title: this.title }, {
        adaptive: true,
        height: 'auto',
        width: '100%',
        scrollable: true,
      });
    },
  },
};
