import { mergeContext } from '@aspectus/vue-utils';

const name = 'control-checker';

export default {
  name,

  functional: true,
  props: {
    b: { default: name },
    tag: { default: 'label' },
    type: { default: 'checkbox' },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  render(h, context) {
    const {
      b,
      tag,
      type,
      checked,
      disabled,
      invalid,
      readonly,
      styling,
    } = context.props;
    return h(
      'tag',
      {
        class: [
          b,
          {
            [`${b}--styling_${styling}`]: !!styling,
            [`${b}--type_${type}`]: !!type,
          },
          {
            'is-checked': checked,
            'is-disabled': disabled,
            'is-invalid': invalid,
            'is-readonly': readonly,
          },
        ],
        attrs: { tag },
      },
      [
        h(
          'input',
          mergeContext(context.data, {
            class: `${b}__element`,
            attrs: { type, disabled, readonly },
            domProps: { checked },
          })
        ),
        h('span', { class: `${b}__label` }),
      ]
    );
  },
};
