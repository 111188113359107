export function install(Vue) {
  // HACK:
  // To support passing scoped slots down the tree.
  // Get from here: https://github.com/vuejs/vue/pull/7765#issuecomment-445835875

  Vue.prototype._b = (function (bind) {
    return function (data, tag, value, asProp, isSync) {
      if (value && value.$scopedSlots) {
        data.scopedSlots = {
          ...value.$scopedSlots,
          ...data.scopedSlots
        };
        delete value.$scopedSlots;
      }

      return bind.apply(this, arguments);
    }
  })(Vue.prototype._b);
}

export default { install };
