import { createHOC } from 'vue-hoc';

import ConnectionErrorHandlerWrapper from '../wrappers/ConnectionErrorHandlerWrapper';

export default function hoc(Component) {
  return createHOC(ConnectionErrorHandlerWrapper, null, {
    props: {
      wrappedComponent: Component
    }
  });
}
