<template lang="pug">

include /mixins.pug

control-stack
  template(#prepend)
    control-date-picker(
      :disabled-time="disabledFrom",
      :value="value && value.min",
      @input="input($event || null, 'min')",
      :format-name="formatName",
      type="time",
      :show-second="false"
      
    )
      template(v-slot:input='slotProps')
        input.mx-input(
          :placeholder="placeholderMin",
          @blur="triggerInput($event.target.value, 'min')"
          @input="triggerInput($event.target.value || null, 'min')",
          type='text'
          v-bind='slotProps.props' 
          v-on='slotProps.events'
          :value="(value && value.min) | formatDate",
        )
  template(#append)
    control-date-picker(
      :placeholder="placeholderMax",
      :disabled-time="disabledTo",
      :value="value && value.max",
      @input="input($event || null, 'max')",
      :format-name="formatName",
      type="time",
      :show-second="false"
    )
      template(v-slot:input='slotProps')
        input.mx-input(
          :placeholder="placeholderMax",
          @blur="triggerInput($event.target.value, 'max')"
          @input="triggerInput($event.target.value || null, 'max')",
          type='text' 
          v-bind='slotProps.props' 
          v-on='slotProps.events'
          :value="(value && value.max) | formatDate",
        )
        //- p {{ (value && value.max) | formatDate }}

</template>

<script>

const MINUTE = 60000;
const ETERNITY = MINUTE * MINUTE * MINUTE * MINUTE;

const properTime = v => {
  return v < 10 ? `0${v}` : v
}

export default {
  name: 'time-range-picker',

  props: ['min', 'max', 'placeholderMin', 'placeholderMax', 'value', 'formatName', 'validateMinMax'],
  computed: {
    disabledFrom() {
      return this.disabledBoth(this.min, this.value && this.value.max || this.max);
    },
    disabledTo() {
      return this.disabledBoth(this.value && this.value.min || this.min, this.max);
    },
  },
  methods: {
    triggerInput(value, key) {
      const [hours, minutes] = value.split(':')
      console.log(hours)
      console.log(minutes)
      if (hours && minutes && hours.length > 1 && minutes.length > 1) {
        const date = new Date()
        date.setHours(0,0,0,0)
        date.setHours(hours)
        date.setMinutes(minutes)
        return this.input(date, key)
      }
    },
    disabledBoth(from, to) {
      let pFrom = new Date(from || 0);
      let pTo = new Date(to || ETERNITY);

      return date => this.validateMinMax && (date && ((from && pFrom && pFrom > date) || (to && pTo && pTo < date)));
    },
    input(value, key) {
      this.$emit('input', Object.assign({}, this.value, { [key]: value }));
    },
  },
};

</script>
