export function segmentsOverlapClean(segments, {
  from = -Infinity, to = +Infinity
} = {}) {
  const fit = segments
    // Only segments that are intersects the range
    .filter(segment => segment.from < to && segment.to > from)
    // Removing all the overflows
    .map(segment => ({
      from: Math.max(segment.from, from),
      to: Math.min(segment.to, to)
    }))
    // Sort segments by (`from`, `to`) from lower to higher.
    .sort((a, b) => (a.from - b.from) || (a.to - b.to));

  // FIXME: This thing here has O(n^2) speed. Please, optimize it.
  for (let i = 0; i < fit.length - 1; i++) {
    for (let j = i + 1; j < fit.length; j++) {
      if (fit[j].from >= fit[i].to) {
        continue;
      }

      const parts = [];

      if (fit[j].from > fit[i].from) {
        parts.push({ from: fit[i].from, to: fit[j].from });
      }

      if (fit[j].to < fit[i].to) {
        parts.push({ from: fit[j].to, to: fit[i].to });
      }

      Array.prototype.splice.apply(fit, [i, 1].concat(parts));

      if (parts.length === 0) {
        i--;
        break;
      }
    }
  }

  return fit;
}

export function segmentsHeight(segments) {
  return segments.reduce((acc, x) => acc + x.to - x.from, 0);
}

export function scroll(element, top, behavior) {
  if (typeof element.scroll === 'function') {
    element.scroll({ top, behavior });
  } else {
    element.scrollTop = top;
  }
}

export class StickScrollPosition {
  static CHANGED_ON_TOP = 1;

  static CHANGED_ON_BOTTOM = 2;

  constructor({
    behavior = 'instant',
    direction = StickScrollPosition.CHANGED_ON_BOTTOM
  } = {}) {
    this.config = { behavior, direction };
  }

  bind(element) {
    this.element = element;

    this.updatePosition();
  }

  getCondition() {
    return {
      top: this.element.scrollTop,
      bottom: this.element.scrollHeight - this.element.scrollTop,
      height: this.element.scrollHeight
    };
  }

  updatePosition() {
    this.last = this.getCondition();
  }

  scroll(top, behavior = this.config.behavior) {
    scroll(this.element, top, behavior);
    this.updatePosition();
  }

  toLast(
    direction = this.config.direction,
    behavior = this.config.behavior
  ) {
    const condition = this.getCondition();
    let top = condition.top;

    if (direction === StickScrollPosition.CHANGED_ON_TOP) {
      top = condition.height - this.last.height + condition.top;
    }

    if (direction === StickScrollPosition.CHANGED_ON_BOTTOM) {
      // top = condition.top - (condition.height - this.last.height);
    }

    this.scroll(top, behavior);
  }

  toBottom() {
    this.scroll(this.element.scrollHeight);
  }
}
