<template lang="pug">

include /mixins.pug

div
  row(appearance="spaced", space="2xl", v-if="form.result && $scopedSlots.message")
    cell(cols="12")
      slot(name="message")
    cell(cols="12", v-if="resettable || $scopedSlots.handlers")
      row(appearance="spaced", space="md", justify="center")
        cell(cols="narrow", v-if="resettable")
          control-button(variant="border_pill", styling="primary", appearance="wide", size="md", @click="form.reset")
            +b.ds-caption.--variant_medium {{ _('Ок') }}
        slot(name="handlers", :form="form")

  slot(v-else="")

</template>

<script>

export default {
  name: 'success-form-displayer',
  props: {
    form: {},
    resettable: Boolean,
  },
};

</script>
