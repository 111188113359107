<template lang="pug">

include /mixins.pug

control-stack
  template(#prepend)
    control-input(
      type="number"
      :placeholder="placeholderMin",
      :min="Math.floor(min || -Infinity)"
      :max="Math.ceil(value && value.max || max || Infinity)"
      :value="value && value.min",
      @input="input($event || null, 'min')",
      :name="name+'_min'"
    )
  template(#append)
    control-input(
      type="number"
      :placeholder="placeholderMax",
      :min="Math.floor(value && value.min || min || -Infinity)"
      :max="Math.ceil(max || Infinity)"
      :value="value && value.max",
      @input="input($event || null, 'max')",
      :name="name+'_max'"
    )

</template>

<script>
export default {
  name: "number-range-picker",
  props: ["min","max","placeholderMin","placeholderMax","value","crossValidation", "name"],
  methods: {
    input(value, key) {
      this.$emit("input", Object.assign({}, this.value, { [key]: value }))
    },
  },
};
</script>
