import { ar } from './TablesTimingController';
import { stringToMinutes } from '@md/ui/components/controllable-table/utils';

export default {
  name: 'tables-calculation-controller',
  props: {
    value: {},
    tables: {},
    total: {},
    hourly: {},
  },
  render(h) {
    return h(null);
  },
  watch: {
    tables: {
      deep: true, immediate: true,
      handler() {
        const activityIds = ar(this.value).map(x => x.activity.id);
        const unfilled = ar(this.tables).filter(x => !activityIds.includes(x.activity.id));

        if (unfilled && unfilled.length) {
          this.update(unfilled);
        }

        this.$nextTick(() => this.calculateTotals());
      },
    },
    value: {
      deep: true, immediate: true, handler: 'calculateTotals',
    },
  },
  methods: {
    calculateTotals() {
      let calculated = 0;
      const totalMap = ar(this.tables).reduce((acc, x) => {
        acc[x.activity.id] = stringToMinutes(x.total) / 60;

        return acc;
      }, {});
      ar(this.value).forEach(value => {
        if (totalMap[value.activity.id] !== value.hours) {
          value.hours = totalMap[value.activity.id];
        }

        const sum = value.hours * value.hourly;

        if (sum !== value.total) {
          value.total = sum;
        }

        calculated += value.total;
      });

      calculated = calculated.toFixed(2);

      if (calculated !== this.total) {
        this.$emit('update:total', calculated);
      }
    },
    update(tables) {
      this.input(ar(this.value).concat(tables.map(({ activity }) => ({
        activity, hours: 0, hourly: this.hourly, total: 0,
      }))));
    },
    input(value) {
      this.$emit('input', value);
    },
  },
};
