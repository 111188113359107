import { createHOCc } from 'vue-hoc';

import { now, comparableDate } from '@md/utils/date';
import { thresholdCheck } from '@md/utils/number';
import { LimitOffsetPaginator } from '@md/utils/paginator';
import LoadableMixin from '@md/utils/loadable/LoadableMixin';
import FeedEventsHandlerMixin from '@md/chat/utils/mixins/FeedEventsHandlerMixin';
import MessagingInjectorMixin from '@md/chat/utils/mixins/MessagingInjectorMixin';

export default createHOCc({
  mixins: [LoadableMixin, FeedEventsHandlerMixin, MessagingInjectorMixin],

  feedHandlers: [
    {
      event: 'chat:create',
      handler(event) {
        if (this.live) {
          this.data.data.unshift(event.body);
        }
      }
    }
  ],

  props: {
    preloadAmount: {
      type: Number,
      default: 40
    },
    live: {
      type: Boolean,
      default: false
    },
    bubble: {
      type: Boolean,
      default: false
    },
    jobId: Number,
    initial: {},
  },

  watch: {
    feed: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getInitialChats();
        }
      }
    }
  },

  data() {
    return {
      data: {
        data: [],
        meta: {}
      }
    };
  },

  computed: {
    items() {
      const chats = this.getLiveChats(this.data.data);

      if (!this.bubble) {
        return chats;
      }

      return chats.sort((a, b) => comparableDate(a.createdAt) - comparableDate(b.createdAt));
    }
  },

  methods: {
    getInitialChats() {
      this.pullChats({
        end: now().toISOString(),
        limit: this.preloadAmount,
        jobId: this.jobId,
      })
        .then(() => {
          if (this.initial) {
            const chat = this.data.data.find(x => x.id === this.initial);
            if (chat) {
              this.$emit('input', chat);
            }
          }
        });
    },

    paginateChats(pagination) {
      this.pullChats({ ...this.data.meta, ...pagination });
    },

    pullChats(parameters) {
      return this.$load(this.feed.receiveChats(parameters)).then(({ data, meta }) => {
        this.data.meta = { ...meta, ...parameters };
        this.data.paginator = new LimitOffsetPaginator({
          offset: meta.offset,
          count: meta.total,
        }, {
          limit: meta.limit,
        });
        data.forEach(this.service.setChat);
        this.data.data = this.data.data.concat(data);

        return { data, meta };
      });
    },

    scroll(event) {
      const element = event.target;
      if (
        thresholdCheck(this.threshold, 0, element.scrollTop) &&
        (this.data.paginator && this.data.paginator.hasNext()) &&
        !this.isLoading
      ) {
        this.paginateChats(this.data.paginator.next());
      }
    }
  }
}, {
  props: {
    chats() {
      return this.items;
    },

    loading() {
      return this.isLoading;
    }
  }
});
