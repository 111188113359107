<template>
  <tag
    :tag="loaderController"
    :resource="resource"
    v-slot="{ receive, loading, result }"
  >
    <tag
      :tag="paginationController"
      :resultPagination="result && result.pagination"
      :receiver="receive"
      :parameters="parameters"
      @update:parameters="changeParameters"
      v-slot="{ filters, changeFilters, pagination, changePagination }"
    >
      <slot
        :parameters="parameters"
        :filters="filters"
        :changeFilters="changeFilters"
        :pagination="pagination"
        :changePagination="changePagination"
        :receive="receive"
        :loading="loading"
        :result="result"
      />
    </tag>
  </tag>
</template>

<script>

export default {
  name: 'paginated-resource-controller',

  props: {
    resource: {},
    parameters: Object,
    changeParameters: Function,
    loaderController: {
      default: 'resource-loader-controller',
    },
    paginationController: {
      default: 'pagination-controller',
    },
  },
};

</script>
