<script lang='js'>
export default {
  name: 'Toggler',
  data() {
    return {
      activeState: false
    }
  },
  props: {
    active: {
      default: false,
      type: Boolean,
      required: false
    },
  },
  methods: {
    toggleActive(v) {
      this.activeState = !this.activeState 
    },
    collapse(state = false) {
      this.activeState = state
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      active: this.activeState,
      toggleActive: this.toggleActive,
      collapse: this.collapse
    })
  },
  created() {
    this.activeState = this.active
  }
}
</script>