import FileUploader from '@md/ui/components/FileUploader.vue';
import LoadingState from '@md/ui/mixins/LoadingState';

export default {
  extends: FileUploader,
  mixins: [LoadingState],
  name: 'profile-uploader-form',
  props: {
    removeResource: {},
    lockUser: Boolean,
  },
  methods: {
    remove(file) {
      this.$load(this.removeResource.execute({ id: file.id }, {}));
      FileUploader.methods.remove.call(this, file);
    },
    input(data) {
      if (this.lockUser) {
        const user = this.USER.GET();
        user.profile.status = '00400-locked';
        this.USER.SET(user);
      }
      console.log(data)

      FileUploader.methods.input.call(this, data);
    },
  },
};
