// import { ContextProvider, ContextConsumer } from './context';
import Icon from './Icon';
import SVGIcon from './SVGIcon';
import LoadingWrapper from './LoadingWrapper';
import ActiveState from './ActiveState';
import Loading from './Loading';
// import LanguageSelector from './LanguageSelector';
// import Pagination from './Pagination';
import FileTemplate from './FileTemplate';
// import SelectionTable from './SelectionTable';
// import TableActionsPanel from './TableActionsPanel';
// import BulkActionsTable from './BulkActionsTable';
import FilePreview from './FilePreview';
import FileUploader from './FileUploader';
// import DiffDisplay from './DiffDisplay';
// import DataDescriptor from './DataDescriptor';
import MessageDisplay from './MessageDisplay';
import SimpleForm from './SimpleForm';
import SuccessFormDisplayer from './SuccessFormDisplayer';
import GlobalFormErrors from './GlobalFormErrors';
import DynamicContentRenderer from './DynamicContentRenderer';
import SimpleDistanceCalculator from './SimpleDistanceCalculator';
import UserDistanceCalculator from './UserDistanceCalculator';
import GalleryController from './GalleryController';

import Toggler from './Toggler';
import Spacer from './Spacer';
import ClickOutside from './ClickOutside';

import Controls from './controls';
// import SimpleTable from './simple-table';

import ControllableTable from './controllable-table';

import SimplePropExtractor from './SimplePropExtractor';
import SocialRegisterAnalizer from './SocialRegisterAnalizer';

import FileDownloader from './FileDownloader';
import StatefulFileDownloader from './StatefulFileDownloader';

import FilesSerializer from './FilesSerializer';

import ChatCounter from './ChatCounter'


function install(Vue) {
  // Vue.component(ContextProvider.name, ContextProvider);
  // Vue.component(ContextConsumer.name, ContextConsumer);
  Vue.component(SocialRegisterAnalizer.name, SocialRegisterAnalizer);
  Vue.component(SimplePropExtractor.name, SimplePropExtractor);

  Vue.component(ActiveState.name, ActiveState);
  Vue.component(LoadingWrapper.name, LoadingWrapper);
  Vue.component(Loading.name, Loading);
  // Vue.component(LanguageSelector.name, LanguageSelector);
  Vue.component(SVGIcon.name, SVGIcon);
  Vue.component('icon', Icon);
  Vue.component('file-template', FileTemplate);
  Vue.component(FilePreview.name, FilePreview);
  Vue.component(ChatCounter.name, ChatCounter);
  // Vue.component(Pagination.name, Pagination);
  // Vue.component(SelectionTable.name, SelectionTable);
  // Vue.component(TableActionsPanel.name, TableActionsPanel);
  // Vue.component(DiffDisplay.name, DiffDisplay);
  Vue.component(FileUploader.name, FileUploader);
  
  Vue.component(SimpleForm.name, SimpleForm);
  Vue.component(SuccessFormDisplayer.name, SuccessFormDisplayer);
  Vue.component(GlobalFormErrors.name, GlobalFormErrors);
  // Vue.component(BulkActionsTable.name, BulkActionsTable);
  Vue.component(SimpleDistanceCalculator.name, SimpleDistanceCalculator);
  Vue.component(UserDistanceCalculator.name, UserDistanceCalculator);
  Vue.component(GalleryController.name, GalleryController);
  Vue.component('dynamic-content-renderer', DynamicContentRenderer);

  // Vue.component('data-descriptor', DataDescriptor);
  Vue.component('message-display', MessageDisplay);

  Vue.use(Controls);
  // Vue.use(SimpleTable);
  Vue.use(ControllableTable);

  Vue.component(Toggler.name, Toggler);
  Vue.component(Spacer.name, Spacer);
  Vue.component(ClickOutside.name, ClickOutside);

  Vue.component(FileDownloader.name, FileDownloader);
  Vue.component(StatefulFileDownloader.name, StatefulFileDownloader);
  Vue.component(FilesSerializer.name, FilesSerializer);
}

export default {
  Icon,
  install,
};
