import { mergeContext } from '@aspectus/vue-utils'

const b = 'control-radio'

export default {
  name: b,
  functional: true,
  props: {
    type: {
      default: 'radio',
    },
  },
  render(h, context) {
    return h(
      'control-checker',
      mergeContext(context.data, { props: { type: context.props.type }, attrs: { b } }),
      context.children,
    )
  },
}
