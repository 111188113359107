<template lang="pug">
  include /mixins.pug

  +b.TR.ds-table__row
    +b.TD.ds-table__cell
      +b.FLAT-PICKR.control-input--styling_blank.--styling_calendar(
        :config='{ ...config, enableTime: false, noCalendar: false, dateFormat: "d.m.yy" }'
        v-model='row.startDate'
      )  
      //- +b.ds-caption--variant_right.--color_4 {{ row.startDate }}
    +b.TD.ds-table__cell(style="min-width: 48px")
      +b.FLAT-PICKR.control-input--styling_blank.--styling_calendar(
        :config='config'
        v-model='row.startTime'
      )
    +b.TD.ds-table__cell
      +b.FLAT-PICKR.control-input--styling_blank.--styling_calendar(
        :config='{ ...config, enableTime: false, noCalendar: false, dateFormat: "d.m.yy" }'
        v-model='row.endDate'
      )  
    +b.TD.ds-table__cell(style="min-width: 48px")
      +b.FLAT-PICKR.control-input--styling_blank.--styling_calendar(
        :config='config'
        v-model='row.endTime'
      )
    +b.TD.ds-table__cell(style="min-width: 48px", v-show="!disablePauses")
      +b.FLAT-PICKR.control-input--styling_blank.--styling_calendar(
        :config='config'
        v-model='row.restTime'
      )
    +b.TD.ds-table__cell
      +b.ds-caption--variant_right.--color_4 {{ workTime.asString }}
</template>

<script>
import { minuteToString, stringToMinutes } from "./utils";
import { German } from 'flatpickr/dist/l10n/de.js';
const reversedDate = string => string.split('.').reverse().join('-')
const parsedDate = (date, time) => new Date(`${date} ${time}`)
const HOURS_24 = 60 * 24;

export default {
  name: "controllable-row",
  props: {
    row: {
      type: Object,
      required: true,
    },
    disablePauses: Boolean
  },
  computed: {
    workTime() {
      const dateStart = parsedDate(reversedDate(this.row.startDate), this.row.startTime).getTime()
      const dateEnd = parsedDate(reversedDate(this.row.endDate), this.row.endTime).getTime()
      const restTime = stringToMinutes(this.row.restTime);
      const total = ((dateEnd - dateStart) / 1000 / 60) - restTime;
      return { total, asString: minuteToString(total) };
    },
    maxStartTime() {
      return minuteToString(stringToMinutes(this.row.endTime) - 1);
    },
    minEndTime() {
      const start = stringToMinutes(this.row.startTime);

      return minuteToString(start === 0 ? start : start + 1);
    }
  },
  watch: {
    workTime: {
      handler(val, old) {
        if (val && old && val.total === old.total) {
          return;
        }

        this.row.workTime = val.total;
        this.$emit('input', Object.assign({}, this.row));
      },
      deep: true,
      immediate: true,
    },
    // 'row.startTime': {
    //   handler(val, old) {
    //     if (val === old) {
    //       return;
    //     }

    //     const startTime = stringToMinutes(val);
    //     const endTime = stringToMinutes(this.row.endTime);

    //     if (startTime >= endTime) {
    //       this.row.endTime = minuteToString(startTime + 1);
    //     }
    //   },
    // },
  },
  data() {
    return {
      config: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        minuteIncrement: 1,
        locale: German,
      },
    };
  },
};
</script>
