/* eslint-disable */
import { DataFeedInterface } from './Interface';
import { now } from '@md/utils/date';
import {
  roomsListResource,
  messagesListResource, messagesCreateResource, messagesActionsReadResource
} from './api';
import centrifugo from './centrifugo';

const EventEmitter = require('events');
const genId = (counter => () => counter++)(1000000000)

function chatAuthors(chats, id, callback) {
  const chat = chats.find(x => x.id === id);
  Object.values(chat.authors).forEach((author, i) => {
    callback.call(this, author, i);
  });
}

function migrateRoomToChat(room) {
  const chat = Object.assign({}, room);
  chat.userTo.displayName = chat.userTo.displayName || chat.userTo.username;
  chat.userFrom.displayName = chat.userFrom.displayName || chat.userFrom.username;
  chat.authorsList = [chat.userFrom, chat.userTo];
  chat.authors = {
    [chat.userFrom.id]: chat.userFrom,
    [chat.userTo.id]: chat.userTo,
  };

  return chat;
}

function migrateMessage(message) {
  const result = Object.assign({}, message);
  result.chatId = message.roomId;
  result.updatedAt = message.modifiedAt;

  return result;
}

function migrateEventData(eventData) {
  eventData.roomId = eventData.chatId;
  delete eventData.chatId
  return eventData;
}


export default class ChatFeed extends DataFeedInterface {
  constructor() {
    super();
    // this.server.emitter.on('event', this.handleEvent.bind(this));
    if (window.USER_CHANNEL) {
      centrifugo.subscribe(window.USER_CHANNEL, this.handleEvent.bind(this));
    }
  }

  handleEvent(event) {
    const { data: { event_class, data } } = event;
    let body = data;
    let type = event_class;

    if (event_class === 'message:send') {
      body = migrateMessage(body);
    }

    if (event_class === 'message:update') {
      body = migrateMessage(body);
    }

    if (event_class === 'room:update') {
      type = 'chat:update';
      body = migrateRoomToChat(body);
    }

    this.emitter.emit(type, { type, body });
  }

  receiveHistory({ end, chatId: room_id, limit, offset } = {}) {
    const created_till = new Date(end).toISOString();
    return messagesListResource.execute({ limit, offset, created_till, room_id }).then(result => {

      return {
        data: result.items.map(migrateMessage),
        meta: result.pagination,
      };
    });
  }

  receiveChats({ end, limit, offset, jobId } = {}) {
    const created_till = new Date(end).toISOString();
    return roomsListResource.execute({ limit, created_till, job_id: jobId, offset }).then(result => {
      return {
        data: result.items.map(migrateRoomToChat),
        meta: result.pagination,
      };
    });
  }

  setConnection(status) {
    this.isOnline = status === 'online';
    this.emitter.emit(`connection:${status}`, {
      type: `connection:${status}`,
      createdAt: now().toISOString(),
      body: {}
    });
  }

  sendMessage(message) {
    return messagesCreateResource.execute({}, migrateEventData(message));
  }

  sendEvent(type, body) {
    if (type === 'message:read') {
      messagesActionsReadResource.execute({ id: body.messageId });
    }
    // this.server.emitter.emit(type, { type, body });
  }
}
