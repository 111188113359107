<template lang="pug">

include /mixins.pug

+b.control-input(
  v-bem:control-input="{ styling, size, type }"
  v-state="{ readonly, invalid }"
)
  +e.INPUT.element.--input.--primary(
    type=""
    :type="type",
    v-model="internal",
    :readonly="readonly",
    @keydown.enter.prevent="",
    ref="input",
    v-bind="$attrs",
    @blur="blur"
  )
  +e.element.--append(v-if="icon || $scopedSlots.icon")
    slot(name="icon")
      icon(:name="icon")

</template>

<script>

import { maps } from '@md/api';

export default {
  name: 'control-address-input',
  props: {
    value: { },
    size: { default: 'md' },
    styling: { default: 'default' },
    type: { default: 'text' },
    readonly: Boolean,
    invalid: Boolean,
    icon: {},
  },

  created() {
    maps().then(this.bindToInput);
  },

  data() {
    this.autocomplete = null;

    return {
      internal: '',
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(value, old) {
        if (value !== old) {
          this.internal = value && value.address || '';
        }
      },
    },
  },

  methods: {
    bindToInput(googleMaps) {
      this.autocomplete = new googleMaps.places.Autocomplete(
        this.$refs.input,
        { types: ['geocode'] }
      );

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        this.internal = this.$refs.input.value;

        this.input({
          address: this.internal,
          point: place.geometry.location.toJSON(),
        });
      });
    },
    blur() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.value && this.internal !== this.value.address) {
            this.input(null);
          }
        }, 400);
      });
    },
    input(value) {
      this.$emit('input', value);
    },
  },
};

</script>
