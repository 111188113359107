import { path, clone, pipe, assocPath } from 'ramda';
import SimpleForm from '@md/ui/components/SimpleForm.vue';

import { profileTo, profileFrom, arrayToIds } from './utils';

const fDate = v => (v && v.toISOString());
const pathEducation = ['profile', 'data', 'education'];
const pathBirthDate = ['profile', 'data', 'birthDate'];
const pathAbout = ['profile', 'data', 'about'];
const getEducation = path(pathEducation);
const getBirthDate = pipe(path(pathBirthDate), v => new Date(v));
const getAbout = pipe(path(pathAbout));
const assocEducation = assocPath(pathEducation);
const assocBirthDate = assocPath(pathBirthDate);
const assocAbout = assocPath(pathAbout);

export default {
  extends: SimpleForm,
  name: 'profile-employee-form',

  methods: {
    createSubmit(observer) {
      return (fields, additionals) => {
        this.USER.SET_SETTINGS_INVALID(observer.touched && observer.invalid)
        // this['nonFieldErrors'] = observer.dirty && observer.invalid ? [this._('Пожалуйста, заполните обязательные поля чтобы активировать профиль')] : []
        return observer.handleSubmit(() => this.submit(fields, additionals))
      }
    },
    setErrors(observer = this.$refs.validator) {
      console.log({ ...observer.flags })
      this['nonFieldErrors'] = observer.flags.invalid ? [this._('Пожалуйста, заполните обязательные поля чтобы активировать профиль')] : []
      console.log(this['nonFieldErrors'])
    },
    transformTo(user) {
      return Object.assign(profileTo(user), {
        education: getEducation(user),
        birthDate: getBirthDate(user),
        about: getAbout(user),
      });
    },
    transformFrom(data) {
      let user = profileFrom(data, clone(this.initial));
      user.profile.subscribeSpecializations = arrayToIds(user.profile.subscribeSpecializations)
      user = assocEducation(data.education, user);
      user = assocBirthDate(fDate(data.birthDate), user);
      user = assocAbout(data.about, user);

      return user;
    },
  },
  mounted() {
    setTimeout(() => {
      this.setErrors()
    }, 100)
  },
};
