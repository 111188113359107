import './public-path';



import Vue from 'vue';
// import Router from 'vue-router';
import I18n from 'vue-i18n';
// import { Store } from 'vuex';

import { extend } from 'vee-validate';
import {
  required, confirmed, email, min, min_value, max, max_value
} from 'vee-validate/dist/rules';
import './styles/index.sass';


import { install } from '@/install';
import { install as installChat } from '@md/chat';
import { install as installBindable} from './bindableScopedSlots'
import { createApp } from '@/app';

function isIE() {
  return navigator.userAgent.indexOf("Trident/") > -1 || navigator.userAgent.indexOf("MSIE") > -1;
}

if (isIE()) {
  document.querySelector("#not-supported").style.display = 'block';
} else {
  Vue.config.devtools = true;

  Vue.config.productionTip = false;

  Vue.use(install);
  Vue.use(installChat);
  Vue.use(installBindable);

  const { app } = createApp({
    Vue, I18n,
  });

  extend('required_range', {
    validate(value, options) {
      const min = required.validate(value && value.min);
      const max = required.validate(value && value.max);
      const valid = options[0] ? true : min.valid && max.valid
      return {
        valid: valid,
        required: min.required || max.required,
      };
    },
    computesRequired: true,
    message: app._('This field is required.'),
  });
  extend('required_array', {
    validate(value) {
      return value && value.reduce && value.length
        ? value.reduce((acc, x) => {
          const current = required.validate(x);
          return {
            valid: acc.valid && current.valid,
            required: acc.required || current.required,
          };
        }, { valid: true, required: false })
        : required.validate(value);
    },
    computesRequired: true,
    message: app._('This field is required.'),
  });
  extend('required', { ...required, message: app._('This field is required.') });
  extend('confirmed', { ...confirmed, message: app._('Passwords must match.') });
  extend('email', { ...email, message: app._('Email is invalid.') });
  extend('min', { ...min, message: app._('Value is too short.') });
  extend('min_value', { ...min_value, message: app._('Minimum value is {min}.') });
  extend('max', { ...max, message: app._('Value is too long.') });
  extend('max_value', { ...max_value, message: app._('Maximum value is {max}.') });

  let translationSpikes = [
      app._('Печатать'),
  ];

  app.$mount('#app');
}

