/**
 * Upload progress descriptor object.
 *
 * @export
 * @class Progress
 */
export class Progress {
  constructor(loaded, total) {
    this.loaded = loaded;
    this.total = total;
    this.relative = (this.loaded / this.total) || 0;
  }
}

export class FileUploader {
  /**
   * Javascript File interface object.
   *
   * @see https://developer.mozilla.org/ru/docs/Web/API/File
   * @memberof FileUploader
   */
  file;

  /**
   * File upload progress data.
   *
   * @memberof FileUploader
   */
  progress = new Progress(0, 1);

  /**
   * Upload event emitter.
   *
   * @memberof FileUploader
   */
  emitter;

  /**
   * Boolean, that tells if the upload process was finished.
   *
   * @memberof FileUploader
   */
  isReady;

  /**
   * Boolean, that tells if the upload process had been started.
   *
   * @memberof FileUploader
   */
  isLoaded;

  constructor(file) {
    this.file = file;

    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
  }

  /**
   * Method, that calls cancellation event of file upload.
   *
   * @memberof FileUploader
   */
  cancel() {
    if (!this.isLoaded) return;

    this.emitter.emit('cancel', this);
  }

  /**
   * Method, that calls deletetion event after upload.
   *
   * @memberof FileUploader
   */
  delete() {
    if (!this.isLoaded) return;
    if (!this.isReady) return this.cancel();

    this.emitter.emit('delete', this);
  }

  /**
   * Sets file object into ready state, with a given data.
   *
   * @param {*} info Information about file.
   * @memberof FileUploader
   */
  ready(info) {
    const total = this.progress.total || 1;
    this.progress = new Progress(total, total);
    this.info = info;
    this.isReady = true;

    this.emitter.emit('ready', this);
  }

  handleError(result) {
    this.ready({ status: 'error', result });
  }

  handleSuccess(result) {
    this.ready({ status: 'success', result });
  }

  handleProgress(event) {
    if (this.isReady) return;

    const { loaded, total } = event;
    this.progress = new Progress(loaded, total);
  }

  upload(handler) {
    const emitter = handler(this.file);

    this.isLoaded = true;
    this.emitter = emitter;

    emitter.on('error', this.handleError);
    emitter.on('success', this.handleSuccess);
    emitter.on('progress', this.handleProgress);

    return this;
  }
}

/**
 * Upload descriptor object.
 *
 * @export
 * @class FileDescriptor
 */
export class FileDescriptor extends FileUploader {
  /**
   * File name.
   *
   * @memberof FileDescriptor
   */
  name;

  /**
   * File url, to display, for example, thumbnail.
   *
   * @memberof FileDescriptor
   */
  url;

  /**
   * File size in bytes.
   *
   * @memberof FileDescriptor
   */
  size;

  /**
   * File mime type.
   *
   * @memberof FileDescriptor
   */
  type;

  /**
   * File resulting information source.
   *
   * @memberof FileDescriptor
   */
  info;

  constructor(file) {
    super(file);

    this.name = this.file.name;
    this.size = this.file.size;
    this.type = this.file.type;

    this.updateUrl();
  }

  updateUrl() {
    const reader = new FileReader();
    reader.onload = e => {
      this.url = e.target.result;
    };
    reader.readAsDataURL(this.file);
  }
}
