<template lang="pug">

include /mixins.pug

+b.jobs-filter
  slot(
    :apply="applyParameters",
    :paginate="paginate",
    :options="prepared",
    :value="value",
    :result="result",
    :loading="loading",
    :order="order",
    :id-getter="idGetter"
    :parameters-to='parametersTo'
    :parameters-from='parametersFrom'
  )

</template>

<script>
import { map, partial } from "ramda";
import LoadingState from "@md/ui/mixins/LoadingState";
import { jobsListUrlGetter, jobsItemsResource } from "@md/jobs/api";

const latestDate = (date) => {
  if (!date) {
    return date;
  }

  date.setHours(23);
  date.setMinutes(59);
  date.setMinutes(59);

  return date;
};
const fSlug = ({ slug } = {}) => slug || null;
const fId = ({ id } = {}) => id || null;
const fRange = ({ min, max } = {}) =>
  ((min || max) && `${min || ""}~${max || ""}`) || null;
const fDate = (date) => (date && date.toISOString()) || null;
const fDateRange = ({ min, max } = {}) =>
  fRange({ min: fDate(min), max: fDate(latestDate(max)) });
const rTo = ([min, max] = []) => ({ min, max });
const tId = (id) => ({ id });
const tRange = (t, { min, max } = {}) => ({ min: t(min), max: t(max) });
const tOption = (opts, k, v) => opts.find((x) => k(x) == v);
const tOptions = (v, k, opts) =>
  map(partial(tOption, [opts || [], k]), v || []);
const tDate = (data) => (data ? new Date(data) : null);
const copy = (v) => Object.assign({}, v);

export default {
  name: "jobs-filter",

  mixins: [LoadingState],
  props: {
    options: Object,
    initial: Object,
    observer: Object,
  },
  data() {
    const prepared = this.prepare(this.options);
    const value = this.parametersTo(this.initial, prepared);

    return {
      value,
      current: this.parametersFrom(value, prepared),
      result: null,
    };
  },
  computed: {
    prepared() {
      return this.prepare(this.options);
    },
  },
  methods: {
    idGetter(value) {
      return value.id;
    },
    prepare(options) {
      const value = copy(options);

      value.employment_range = tRange(tDate, value.employment_range || {});
      value.hourly_payment_range = tRange(
        parseFloat,
        value.hourly_payment_range || {}
      );
      value.distance_range = tRange(parseFloat, value.distance_range || {});

      return value;
    },
    parametersTo(parameters, prepared = this.prepared) {
      const value = copy(parameters);
      const tO = (name) =>
        tOptions(value[name], this.idGetter, prepared[name]) || null;
      value.categories = tO("categories");
      value.positions = tO("positions");

      value.specializations = tO("specializations");

      value.order_by = tId(value.order_by);
      value.hourly_payment_range = tRange(
        parseFloat,
        rTo(value.hourly_payment_range || [])
      );
      value.distance_range = tRange(
        parseFloat,
        rTo(value.distance_range || [])
      );
      value.employment_range = tRange(tDate, rTo(value.employment_range || []));

      if (parameters.latitude && parameters.longitude) {
        const { latitude: lat, longitude: lng, address } = parameters;
        value.location = {
          address,
          point: {
            lat,
            lng,
          },
        };
      } else {
        value.location =
          this.$root.USER.GET("id") &&
          this.$root.USER.GET("profile", "data", "address");
      }

      return value;
    },
    parametersFrom(parameters, prepared = this.prepared) {
      const value = {
        category: fSlug(parameters.categories || {}),
        order_by: fId(parameters.order_by || {}) || parameters.order_by || null,
        filters: {
          position: fSlug(parameters.positions || {}),
          specialization:
            parameters.specializations && parameters.specializations.length
              ? parameters.specializations.map(fSlug)
              : null,
          "hourly-payment": fRange(parameters.hourly_payment_range),
          employment: fDateRange(parameters.employment_range),
          distance: fRange(parameters.distance_range),
          latitude: parameters.location ? parameters.location.point.lat : null,
          address: parameters.location
            ? parameters.location.address.replace(/,/g, "")
            : null,
          longitude: parameters.location ? parameters.location.point.lng : null,
        },
      };

      return value;
    },
    async applyParameters(value) {
      let isValid = await this.observer.validate();
      if (isValid) {
        this.value = value;
        this.current = this.parametersFrom(this.value);
        this.changeUrl(this.current);
        this.load(this.current);
      }
    },
    load(parameters) {
      this.$load(jobsItemsResource.execute(parameters))
        .then((r) => r.text())
        .then((result) => {
          this.result = result;
        });
    },
    changeUrl(parameters) {
      window.history.replaceState({}, null, jobsListUrlGetter(parameters));
    },
    paginate(page) {
      const value =
        page > 1 ? Object.assign({ page }, this.current) : this.current;
      this.changeUrl(value);
      this.load(value);
    },
    order(order_by) {
      this.value.order_by = order_by;
      this.current = Object.assign({}, this.current, {
        order_by: fId(order_by || {}) || order_by || null,
      });
      this.changeUrl(this.current);
      this.load(this.current);
    },
  },
};
</script>
