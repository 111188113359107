<template lang="pug">
  include /mixins.pug
  +b.TABLE.ds-table--variant_bordered.--variant_cabinet.--variant_bids-table(v-bem:ds-table='{variant: index }')
    +e.THEAD.thead
      +e.TR.row
        +e.TH.cell(:colspan='6 - !!activity.disable_pauses')
          +b.ds-caption--variant_center.--variant_medium {{ activity.title }}
      +e.TR.row
        +e.TH.cell(colspan='4')
          +b.ds-caption--variant_center.--variant_medium {{ _('Время') }}
        +e.TH.cell(v-show="!activity.disable_pauses")
          +b.ds-caption--variant_center.--variant_medium {{ _('Часы паузы') }}
        +e.TH.cell
          +b.ds-caption--variant_center.--variant_medium {{ _('Часы работы') }}
      +e.TR.row
        +e.TH.cell
          +b.ds-caption--variant_center.--variant_medium {{ _('Начало') }}
        +e.TH.cell
          +b.ds-caption--variant_center.--variant_medium {{ _('С') }}
        +e.TH.cell
          +b.ds-caption--variant_center.--variant_medium {{ _('Конец') }}
        +e.TH.cell
          +b.ds-caption--variant_center.--variant_medium {{ _('По') }}
        +e.TH.cell(v-show="!activity.disable_pauses")
          +b.ds-caption--variant_center.--variant_medium {{ _('В часах') }}
        +e.TH.cell
          +b.ds-caption--variant_center.--variant_medium {{ _('В часах') }}
    +e.TBODY
      controllable-row(
        v-for='(row, index) in table',
        :key='index',
        :row='row',
        @input='table[index] = row',
        :disable-pauses="activity.disable_pauses"
      )
    +e.TFOOT
      +e.TR.row
        +e.TD.cell(:colspan='5 - !!activity.disable_pauses')
          +b.ds-caption.--color_4.--variant_medium {{ _('Сумма:') }}
        +e.TD.cell(colspan='1')
          +b.ds-caption--variant_right.--color_4.--variant_medium {{ totalWorkTime }}

</template>

<script>
import { minutesToString } from './utils';
export default {
  name: "controllable-table",
  props: {
    index: {},
    total: {},
    table: {},
    activity: {},
  },
  data() {
    return {
      totalWorkTime: this.total || '00:00',
    };
  },
  watch: {
    table: {
      deep: true,
      immediate: true,
      handler(value) {
        const total = (value && value.length ? value : []).reduce((total, row) => (total += row.workTime), 0)
        const totalHours = parseInt(total / 60);
        const totalMinutes = total % 60;

        this.totalWorkTime = minutesToString(totalHours, totalMinutes);
      },
    },
    totalWorkTime: {
      immediate: true,
      handler(total, old) {
        if (total !== old) {
          this.$emit('input', {
            total, table: this.table.concat([]), activity: this.activity,
          });
        }
      },
    },
  },
};
</script>
