import MessagingInjectorMixin from './MessagingInjectorMixin';

export default {
  mixins: [MessagingInjectorMixin],

  data() {
    return {
      selected: null
    };
  },

  computed: {
    chat() {
      return this.selected && this.service.chats[this.selected.id];
    }
  }
};
