import { path, clone } from 'ramda';

const GLOBALS_KEY = 'zvnowoweirueprtyvnzbcasfhbidf';

export function createGlobalsAccessor(name, context, initializer) {
  const accessor = {
    SET_SETTINGS_INVALID(value) {
      accessor.SET({ ...accessor.GET(), isEdited: !value });
    },
    GET() {
      const g = context.$root[GLOBALS_KEY];
      return path(arguments, g && g[name]);
    },
    SET(value) {
      context.$root[GLOBALS_KEY][name] = clone(value);
      context.$root[GLOBALS_KEY] = Object.assign({}, context.$root[GLOBALS_KEY]);
      accessor.$INITIALIZE(value);
    },
    $INITIALIZED: false,
    $INITIALIZE(value) {
      accessor.$INITIALIZED = true;
      initializer && initializer(accessor, value);
    },
  };

  return accessor;
}

function initializeAccessor(accessor, initial) {
  if (!accessor.$INITIALIZED) {
    accessor.SET(initial);
  }
}

export function createGlobalsMixin(name, initial, initializer) {
  return {
    computed: {
      [name]() {
        return this.$root[`${name}_accessor`];
      },
    },
    data() {
      let accessor = this.$root[`${name}_accessor`];

      if (this === this.$root) {
        accessor = createGlobalsAccessor(name, this, initializer);
        this.$nextTick(() => initializeAccessor(accessor, initial));
      } else {
        initializeAccessor(accessor, initial);
      }

      const dataObject = {};

      if (this === this.$root) {
        dataObject[`${name}_accessor`] = accessor;
        dataObject[GLOBALS_KEY] = {};
      }

      return dataObject;
    },
  };
}
