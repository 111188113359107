import { jsonRequestMiddleware } from '@aspectus/resource';
import { createReceiver } from '@md/resources';
import { prefixAPI } from '@md/api';


export const roomsListResource = createReceiver(prefixAPI(
  '/chat/rooms/list/{?limit,created_till,job_id,offset}'
));

export const messagesListResource = createReceiver(prefixAPI(
  '/chat/messages/list/{?limit,room_id,created_till,offset}'
));
export const messagesUnreadResource = createReceiver(prefixAPI('/chat/messages/unread/'));
export const messagesCreateResource = createReceiver(prefixAPI(
  '/chat/messages/create/'
))
  .config('method', 'POST')
  .middleware(jsonRequestMiddleware);
export const messagesActionsReadResource = createReceiver(prefixAPI(
  '/chat/messages{/id}/actions/read/'
))
  .config('method', 'PUT')
  .middleware(jsonRequestMiddleware);
