export default {
  name: 'social-analizer',
  computed: {
    isActive() {
      return location.search.includes('is-registration')
    }
  },
  mounted() {
    this.isActive && this.$emit('notify', { context: this })
  },
  render() {
    return this.$scopedSlots.default({
      isActive: this.isActive
    })
  }
}