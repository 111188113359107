const UserConfirm = () => import('./UserConfirm')

export default {
  name: 'user-restriction-opener',
  methods: {
    openModal(link, title, text, buttonText) {
      return this.$modal.show(UserConfirm, {
        link,
        title,
        text, 
        buttonText
        
      }, {
        'max-width': 800,
        resizable: true,
        adaptive: true,
      })
    }
  },
  render(h) {
    return this.$scopedSlots.default({
      openModal: this.openModal
    })
  }
}