<template lang="pug">

component(
  :is="wrappedComponent",
  v-bind="injectSlots($attrs)",
  v-on="$listeners",
  :slotAreas="slotAreasMerged"
)
  template(slot="failed-message-label", v-if="!windowOpened && failed.length", slot-scope="label")
    .wds-chat-room__label(@click="openWindow")
      button.control-button.control-button--style_default.control-button--size_xs.is-error
        icon(name="announcement", size="lg")
        span.tag.tag--type_error.tag--round.tag--super(v-text="failed.length")

  template(slot="failed-message-window", slot-scope="window")
    wds-dialog-window(
      ref="window",
      :dismissible="false",
      :opened="window.opened",
      @input="window.handler",
      @open="windowOpened = true",
      @close="windowOpened = false"
    )
      slot(
        name="failed-messages",
        :messages="failed",
        :resend="resend",
        :remove="remove"
      )

  slot(:name="slot", :slot="slot", v-for="slot in namedSlots()")

</template>

<script>

import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';
import FeedEventsHandlerMixin from '../../../utils/mixins/FeedEventsHandlerMixin';
import { SlotAreasMergerMixin } from '@md/utils/slot-areas';

const currentSlotAreas = {
  dialogLabels: ['failed-message-label'],
  dialogWindows: ['failed-message-window']
};

export default {
  mixins: [SlotAreasMergerMixin, MessagingInjectorMixin, FeedEventsHandlerMixin],

  currentSlotAreas,

  feedHandlers: [
    { event: 'message:failed', handler(event) { this.fail(event.body); } },
  ],

  props: ['wrappedComponent'],

  data() {
    return {
      failed: [],
      windowOpened: false
    };
  },

  methods: {
    fail(message) {
      this.failed.push(message);

      if (this.failed.length === 1) {
        this.openWindow();
      }
    },

    resend(message) {
      this.feed.sendMessage(message);
      this.remove(message);
    },

    remove(message) {
      const index = this.failed.indexOf(message);

      if (index != -1) {
        this.failed.splice(index, 1);
      }

      if (!this.failed.length) {
        this.closeWindow();
      }

      /**
      * Event, that fires on failed message being removed.
      *
      * @event failed-message-remove
      * @type {object} Message object.
      */
      this.$emit('failed-message-remove', message);
    },

    openWindow() {
      this.$refs.window.open();
    },

    closeWindow() {
      this.$refs.window.close();
    }
  }
};

</script>
