import { renderSlim } from '@aspectus/vue-utils';

export default {
  name: 'active-state',
  data() {
    return {
      value: null,
    };
  },
  props: {
    initial: {},
  },
  watch: {
    initial: {
      immediate: true,
      handler(value, old) {
        if (value === old) {
          return;
        }

        this.value = value;
      },
    },
  },
  methods: {
    set(value) {
      this.value = value;
    },
  },
  render(h) {
    return renderSlim(
      this.$scopedSlots.default({ value: this.value, set: this.set }), h
    );
  },
};
