/**
 * Chat room component.
 *
 * Currently it's a complex and clumsy component, that displays chat
 * conversation, handles message submitting, live updates chat.
 *
 * Must be reworked into several different components.
 *
 * TODO:
 *
 *  - [x] Separate file uploading handler to a HOC.
 *  - [ ] Rework component to make it dumb, and separate functionality
 *  into the HOCs.
 *
 * HOCs:
 *
 * - `fileUploaderWrapper` :: HOC that is responsible of file uploading
 *  process and file messages sending.
 *
 * @version 0.8.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */

import { ChatRoomMixin } from './mixins';
import { feedHandlers, typerHandlers } from './handlers';

export default {
  name: 'WdsChatRoom',

  mixins: [ChatRoomMixin],

  feedHandlers,
  typerHandlers,

  props: {
    height: {
      type: String,
      default: 'auto'
    }
  },

  methods: {
    handleTyping() {
      this.typer.typeHandler();
      this.$emit('typing');
    }
  }
};
