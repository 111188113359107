import Vue from 'vue';
import { makeF, renderSlim } from '@aspectus/vue-utils';

const DYNAMIC_COMPONENTS_CACHE = {};

export const createDynamicComponent = template => {
  const compiled = Vue.compile(template);

  return {
    props: ['context'],
    render: compiled.render,
    staticRenderFns: compiled.staticRenderFns,
  };
};

export const getDynamicComponent = template => {
  if (!DYNAMIC_COMPONENTS_CACHE[template]) {
    DYNAMIC_COMPONENTS_CACHE[template] = createDynamicComponent(template);
  }

  return DYNAMIC_COMPONENTS_CACHE[template];
};

export default makeF(
  (h, context) => {
    const { template } = context.props;

    if (!template) {
      return renderSlim(context.scopedSlots.default({ context: context.props.context }), h);
    }

    return h(
      getDynamicComponent(template),
      { props: { context: context.props.context } }
    );
  },
  ['context', 'template']
);
