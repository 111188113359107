import { mergeContext } from '@aspectus/vue-utils';
import { generateModifierClassNames } from '@aspectus/bem';

const b = 'control-date-picker';

export const DATE_CONFIG = 'datetime';

function formatFabric(d, name = DATE_CONFIG, context) {
  return {
    stringify: date => {
      return context.parent.$options.filters.localizeDate((date ? d(date, name) : ''))
    },
    parse: date => {
      return new Date(date.split('.').reverse().join('/'))
    },
  };
}

export default {
  name: b,
  functional: true,

  props: {
    format: {},
    formatName: {
      default: DATE_CONFIG,
    },
    size: { default: 'md' },
    styling: { default: 'default' },
    variant: { default: 'select' },
    rangeSeparator: { default: ' - ' },
    lang: { default: 'ru' },
  },

  render(h, context) {
    const {
      size,
      styling,
      rangeSeparator,
      format,
      variant,
      formatName,
    } = context.props;

    console.log(context.props)

    return h(
      'tag',
      {
        class: [b, generateModifierClassNames(b, { styling, size, variant }, '--', '_')],
        attrs: {
          b,
          m: { styling, size, variant },
        },
      },
      [
        h(
          'tag',
          mergeContext(context.data, {
            class: `${b}__element`,
            attrs: {
              tag: 'vue-date-picker',
              rangeSeparator,
              format: format || formatFabric(context.parent.$d.bind(context.parent), formatName, context),
            },
          })
        ),
      ]
    );
  },
};
