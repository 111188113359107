import { jsonRequestMiddleware } from '@aspectus/resource';
import { createReceiver } from '@md/resources';
import { prefixAPI } from '@md/api';

export const tablesCreateResource = createReceiver(prefixAPI('/recruit/tables/create/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'POST');
export const tablesUpdateResource = createReceiver(prefixAPI('/recruit/tables{/id}/update/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'PUT');
export const tablesDeleteResource = createReceiver(prefixAPI('/recruit/tables{/id}/delete/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'DELETE');
