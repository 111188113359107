<script>
export default {
  name: 'chat-counter',
  props: {
    count: {
      required: true
    },
    currentId: {
      required: true
    },
    changedId: {
      required: true
    },
  },
  data() {
    return {
      localCount: 0
    }
  },
  watch: {
    changedId: {
      handler(v) {
        if (v == this.currentId) {
          this.localCount++
        }
      }
    }
  },
  methods: {},
  created() {
    this.localCount = this.count
  },
  render(h) {
    return this.$scopedSlots.default({
      count: this.localCount
    })
  }
}
</script>