import { now } from '@md/utils/date';
import { thresholdCheck } from '@md/utils/number';
import { LimitOffsetPaginator } from '@md/utils/paginator';
import { WindowVisibility } from '@md/utils/window-visibility';
import { StickScrollPosition } from '@md/utils/stick-scroll';
import { TypeHandler } from '../../utils/type-handler';
import LoadableMixin from '@md/utils/loadable/LoadableMixin';
import FeedEventsHandlerMixin from '../../utils/mixins/FeedEventsHandlerMixin';
import TyperEventsHandlerMixin from '../../utils/mixins/TyperEventsHandlerMixin';
import MessagingInjectorMixin from '../../utils/mixins/MessagingInjectorMixin';
import { SlotAreasMixin } from '@md/utils/slot-areas';

/**
 * @mixin
 */
export const ChatMechanicsMixin = {
  mixins: [LoadableMixin, FeedEventsHandlerMixin],

  feedHandlers: [],

  props: {
    chat: {
      type: Object,
      required: true
    },
    preloadAmount: {
      type: Number,
      default: 40
    },
    userId: [Number, String]
  },

  data() {
    return {
      text: '',
      messages: {
        data: [],
        meta: {}
      },
      author: {}
    };
  },

  watch: {
    chat: { immediate: true, handler: 'watchChat' },
    'chat.authors': { immediate: true, handler: 'updateAuthor' },
    userId: { immediate: true, handler: 'updateAuthor' }
  },

  methods: {
    updateAuthor() {
      this.author = this.chat.authors[this.userId];
    },

    watchChat(value, old) {
      if (value) {
        if (!old || value.id !== old.id) {
          this.messages = { data: [], meta: {} };
          this.getInitialMessages();
        }
      }
    },

    getInitialMessages() {
      return this.pullMessages({
        end: now().toISOString(),
        chatId: this.chat.id,
        limit: this.preloadAmount,
      });
    },

    paginateMessages(pagination) {
      this.pullMessages({ ...this.messages.meta, ...pagination });
    },

    pullMessages(parameters) {
      return this.$load(this.feed.receiveHistory(parameters)).then(({ data, meta }) => {
        this.messages.meta = { ...parameters, ...meta };
        this.messages.paginator = new LimitOffsetPaginator({
          offset: meta.offset,
          count: meta.total,
        }, meta.limit);
        this.messages.data = data.reverse().concat(this.messages.data);

        return { data, meta };
      });
    },

    sendText() {
      if (!this.feed.isOnline) return;

      const content = this.text.trim();

      if (content) {
        this.sendMessage('text', { content });
        this.text = '';
        this.$refs.messageArea.focus();
      }
    },

    sendMessage(type, body) {
      const message = {
        type,
        body,
        authorId: this.userId,
        chatId: this.chat.id
      };

      this.feed.sendMessage(message);
    },

    sendEvent(type, body) {
      this.feed.sendEvent(type, body);
    }
  }
};

/**
 * @mixin
 */
export const ChatRoomMixin = {
  mixins: [
    ChatMechanicsMixin,
    TyperEventsHandlerMixin,
    MessagingInjectorMixin,
    SlotAreasMixin
  ],

  props: {
    typing: {
      type: Array,
      default: () => []
    },
    threshold: {
      type: Number,
      default: 2
    }
  },

  watch: {
    'visibility.visible'(value, old) {
      if (old !== value && value === true) {
        this.readMessages();
      }
    }
  },

  data() {
    return {
      visibility: new WindowVisibility(),
      typer: new TypeHandler({ startTimeout: 800 }).bind(),
      scroller: new StickScrollPosition({
        direction: StickScrollPosition.CHANGED_ON_TOP
      })
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.scroller.bind(this.$refs['conversation-wrapper']);
      this.visibility.bind();
      this.scroller.toBottom();
    },

    messageStatus(status, message) {
      this.sendEvent(`message:${status}`, {
        messageId: message.id,
        chatId: this.chat.id
      });
    },

    readMessages() {
      const unread = this.messages.data
        .filter(x => x.status !== 'read')
        .filter(x => x.authorId !== this.userId);

      unread.forEach(message => {
        this.messageStatus('read', message);
      });
    },

    scroll(event) {
      if (
        thresholdCheck(this.threshold, 0, event.target.scrollTop) &&
        (this.messages.paginator && this.messages.paginator.hasNext()) &&
        !this.isLoading
      ) {
        this.paginateMessages(this.messages.paginator.next());
      }
      this.scroller.updatePosition();
    },

    pullMessages(meta) {
      ChatMechanicsMixin.methods.pullMessages.call(this, meta).then(() => {
        this.readMessages();
        this.$nextTick(() => this.scroller.toLast());
      });
    },

    sendMessage() {
      ChatMechanicsMixin.methods.sendMessage.apply(this, arguments);
      this.scroller.toBottom();
    },

    handleTyping() {
      this.$emit('typing');
    },

    handleInput(text) {
      this.text = text;
    }
  }
};
