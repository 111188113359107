import BemComponentMixin from '@md/utils/bem/BemComponentMixin'

/**
 * Chat list display component.
 *
 * HOCs:
 *
 * - `feedChatListHandler` :: HOC to load chats from provider's feed.
 *  Also live updates contained chats on their modification.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatList',

  mixins: [BemComponentMixin],

  bem: {
    block: 'wds-chat-list',
    modifierProps: []
  },

  props: {
    /**
     * Determines whether data is loading.
     */
    loading: {
      type: Boolean,
      default: false
    },

    /**
     * Current user identifier.
     */
    userId: [Number, String],

    /**
     * Chat list to display.
     */
    chats: {
      type: Array
    },

    /**
     * Currently selected chat.
     */
    activeId: {
      type: [Number, String],
      default: null
    },
    preloadAmount: {
      type: Number,
      default: 40
    },
    live: {
      type: Boolean,
      default: false
    },
    bubble: {
      type: Boolean,
      default: false
    },
    reversed: Boolean,
  },

  methods: {
    isActive(chat) {
      if (this.activeId === null) return false

      return this.activeId === chat.id
    },

    select(chat) {
      this.$emit('input', chat)
    },

    filter(parameters) {
      this.$emit('filter', parameters)
    },

    scroll(event) {
      this.$emit('scroll', event)
    }
  }
}
