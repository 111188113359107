import { partialRight } from 'ramda'
import { baseResource as superResource } from '@aspectus/resource'
import {
  xCSRFMiddleware, multipartRequestMiddleware,
  jsonTransformer, defaultDataTransformer, raiseHttpErrorTransformer,
  superagentJsonTransformer,
} from '@aspectus/resource-commons'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import fetcher from '@aspectus/superagent-fetch/src/main'

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .transform(raiseHttpErrorTransformer)
export const receiveResource = baseResource
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)
export const sendResource = baseResource
  .middleware(multipartRequestMiddleware)
  .transform(jsonTransformer)
  .config('method', 'POST')
export const sendFileResource = baseResource
  .fetcher(fetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST');

export const createResource = (template, base = baseResource) => (
  base.url(makeTemplateGetter(template))
)
export const createReceiver = partialRight(createResource, [receiveResource])
export const createSender = partialRight(createResource, [sendResource])
export const createFileSender = partialRight(createResource, [sendFileResource])
