export default {
  name: 'prop-extractor',
  props: {
    attr: {
      required: true,
    }
  },
  render(h) {
    return this.$scopedSlots.default({
      attr: this.attr.split(' ')[0]
    })
  }
}