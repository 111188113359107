import { mergeContext } from '@aspectus/vue-utils'

export default {
  name: 'control-stack',
  functional: true,
  render(h, context) {
    return (
      <ds-panel space='md' variant='stack'>
        <ds-panel-element offset='bottom'>
          {context.scopedSlots.prepend()}
        </ds-panel-element>
        <ds-panel-element>
          {context.scopedSlots.append()}
        </ds-panel-element>
      </ds-panel>

    )
  }
}