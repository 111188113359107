import BemComponentMixin from '@md/utils/bem/BemComponentMixin';

/**
 * @mixin
 */
export const MessageComponentMixin = {
  mixins: [BemComponentMixin],

  props: {
    /**
     * Message type.
     */
    type: String,
    /**
     * Message.
     */
    message: Object,

    /**
     * Attaches message to left or right bound of the conversation body.
     */
    alignment: {
      type: String,
      default: 'left'
    },
    /**
     * Devotes message to self or foreign user.
     */
    devoted: {
      type: String,
      default: 'foreign'
    }
  }
};
