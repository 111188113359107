import groupWith from 'ramda/src/groupWith'
import Message from '../Message/Message'
import MessagesReceiverMixin from '../../utils/mixins/MessagesReceiverMixin'

const authorGrouper = groupWith((a, b) => a.authorId === b.authorId)

const dateGrouper = groupWith((a, b) => a.createdAt === b.createdAt)

/**
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatConversation',
  mixins: [MessagesReceiverMixin],

  components: {
    Message
  },

  data() {
    return {
      groups: [],
    };
  },

  watch: {
    messages: {
      immediate: true,
      handler(value) {
        this.groups = this.groupMessages(value);
      },
    },
  },

  methods: {
    transformMessage(message) {
      const result = {}
      Object.assign(result, message)
      result.author = this.authors[result.authorId] || null
      return result
    },

    constructGroup(messages) {
      const [message] = messages

      return {
        messages,
        id: message.id,
        author: message.author
      }
    },
    contructByDate(acc, item) {
      const dateAt = item.createdAt ? item.createdAt.split('T')[0] : item.updatedAt.split('T')[0]
      acc[dateAt] = acc[dateAt] || []
      acc[dateAt].push(item)
      return acc
    },

    groupMessages(messages) {
      const groups = messages
        .map(this.transformMessage.bind(this))
        .reduce(this.contructByDate, {})
      Object.keys(groups).forEach(group => {
        groups[group] = authorGrouper(groups[group]).map(this.constructGroup)
      })
      return groups
    },

    getGroupDevotion(group) {
      return group.author && group.author.id === this.author.id ? 'self' : 'foreign'
    },

    getGroupAlignment(group) {
      return group.author && group.author.id === this.author.id ? 'right' : 'left'
    }
  }
}
