<script>
import { mergeContext } from '@aspectus/vue-utils';

// const MIME_ICON_MAP = {
//   'audio/aac': 'mp3',
//   'application/x-abiword': 'doc',
//   'application/x-freearc': 'zip',
//   'video/x-msvideo': 'avi',
//   'application/vnd.amazon.ebook': 'doc',
//   'application/octet-stream': 'exe',
//   'image/bmp': 'jpg',
//   'application/x-bzip': 'zip',
//   'application/x-bzip2': 'zip',
//   'application/x-csh': 'zip',
//   'text/css': 'txt',
//   'text/csv': 'xls',
//   'application/msword': 'doc',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
//   'application/epub+zip': 'doc',
//   'application/gzip': 'zip',
//   'image/gif': 'jpg',
//   'text/html': 'doc',
//   'image/vnd.microsoft.icon': 'jpg',
//   'image/jpeg': 'jpg',
//   'text/javascript': 'txt',
//   'application/json': 'txt',
//   'application/ld+json': 'txt',
//   'audio/midi': 'mp3',
//   'audio/x-midi': 'mp3',
//   'text/javascript': 'txt',
//   'audio/mpeg': 'mp3',
//   'video/mpeg': 'avi',
//   'application/vnd.apple.installer+xml': 'exe',
//   'application/vnd.oasis.opendocument.presentation': 'ppt',
//   'application/vnd.oasis.opendocument.spreadsheet': 'xls',
//   'application/vnd.oasis.opendocument.text': 'doc',
//   'audio/ogg': 'mp3',
//   'video/ogg': 'avi',
//   'audio/opus': 'mp3',
//   'image/png': 'png',
//   'application/pdf': 'pdf',
//   'application/php': 'txt',
//   'application/vnd.ms-powerpoint': 'ppt',
//   'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
//   'application/vnd.rar': 'zip',
//   'application/rtf': 'doc',
//   'application/x-sh': 'exe',
//   'image/svg+xml': 'jpg',
//   'application/x-tar': 'zip',
//   'image/tiff': 'jpg',
//   'video/mp2t': 'avi',
//   'text/plain': 'txt',
//   'audio/wav': 'mp3',
//   'audio/webm': 'mp3',
//   'video/webm': 'avi',
//   'image/webp': 'jpg',
//   'application/xhtml+xml': 'txt',
//   'application/vnd.ms-excel': 'xls',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
//   'application/xml': 'txt',
//   'text/xml': 'txt',
//   'application/zip': 'zip',
//   'video/3gpp': 'avi',
//   'audio/3gpp': 'mp3',
//   'video/3gpp2': 'avi',
//   'audio/3gpp2': 'mp3',
//   'application/x-7z-compressed': 'zip',
// };

// const getIcon = mime => `file-type/${MIME_ICON_MAP[mime] || 'file'}`;

export default {
  name: 'file-preview',
  functional: true,
  props: ['mime'],

  render(h, context) {
    const { mime } = context.props;
    return h('file-template', mergeContext({
      props: { mime },
    }, context.data), context.children);
  },
};

// const MIME_ICON_MAP = {
//   'audio/aac': '.aac',
//   'application/x-abiword': '.abw',
//   'application/x-freearc': '.arc',
//   'video/x-msvideo': '.avi',
//   'application/vnd.amazon.ebook': '.azw',
//   'application/octet-stream': '.bin',
//   'image/bmp': '.bmp',
//   'application/x-bzip': '.bz',
//   'application/x-bzip2': '.bz2',
//   'application/x-csh': '.csh',
//   'text/css': '.css',
//   'text/csv': '.csv',
//   'application/msword': '.doc',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
//   'application/vnd.ms-fontobject': '.eot',
//   'application/epub+zip': '.epub',
//   'application/gzip': '.gz',
//   'image/gif': '.gif',
//   'text/html': '.html',
//   'image/vnd.microsoft.icon': '.ico',
//   'text/calendar': '.ics',
//   'application/java-archive': '.jar',
//   'image/jpeg': '.jpg',
//   'text/javascript': '.js',
//   'application/json': '.json',
//   'application/ld+json': '.jsonld',
//   'audio/midi audio/x-midi': '.midi',
//   'text/javascript': '.mjs',
//   'audio/mpeg': '.mp3',
//   'video/mpeg': '.mpeg',
//   'application/vnd.apple.installer+xml': '.mpkg',
//   'application/vnd.oasis.opendocument.presentation': '.odp',
//   'application/vnd.oasis.opendocument.spreadsheet': '.ods',
//   'application/vnd.oasis.opendocument.text': '.odt',
//   'audio/ogg': '.oga',
//   'video/ogg': '.ogv',
//   'application/ogg': '.ogx',
//   'audio/opus': '.opus',
//   'font/otf': '.otf',
//   'image/png': '.png',
//   'application/pdf': '.pdf',
//   'application/php': '.php',
//   'application/vnd.ms-powerpoint': '.ppt',
//   'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
//   'application/vnd.rar': '.rar',
//   'application/rtf': '.rtf',
//   'application/x-sh': '.sh',
//   'image/svg+xml': '.svg',
//   'application/x-shockwave-flash': '.swf',
//   'application/x-tar': '.tar',
//   'image/tiff': '.tiff',
//   'video/mp2t': '.ts',
//   'font/ttf': '.ttf',
//   'text/plain': '.txt',
//   'application/vnd.visio': '.vsd',
//   'audio/wav': '.wav',
//   'audio/webm': '.weba',
//   'video/webm': '.webm',
//   'image/webp': '.webp',
//   'font/woff': '.woff',
//   'font/woff2': '.woff2',
//   'application/xhtml+xml': '.xhtml',
//   'application/vnd.ms-excel': '.xls',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
//   'application/xml': '.xml',
//   'text/xml': '.xml',
//   'application/vnd.mozilla.xul+xml': '.xul',
//   'application/zip': '.zip',
//   'video/3gpp': '.3gp',
//   'audio/3gpp': '.3gp',
//   'video/3gpp2': '.3g2',
//   'audio/3gpp2': '.3g2',
//   'application/x-7z-compressed': '.7z',
// };

</script>
