<template lang="pug">

component(
  :is="wrappedComponent",
  v-bind="injectSlots($attrs)",
  v-on="$listeners",
  :slotAreas="slotAreasMerged"
)
  template(slot="connection-error-label", v-if="!windowOpened && !feed.isOnline", slot-scope="label")
    .wds-chat-room__label(@click="openWindow")
      button.control-button.control-button--style_default.control-button--size_xs.is-error
        icon(name="sync_disabled", size="lg")

  template(slot="connection-error-window", slot-scope="window")
    wds-dialog-window(
      ref="window",
      :dismissible="false",
      :opened="window.opened",
      @input="window.handler",
      @open="windowOpened = true",
      @close="windowOpened = false"
    )
      slot(name="connection-error")
        .tag.tag--type_error Connection lost, trying to reconnect.

  slot(:name="slot", :slot="slot", v-for="slot in namedSlots()")

</template>

<script>

import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';
import FeedEventsHandlerMixin from '../../../utils/mixins/FeedEventsHandlerMixin';
import { SlotAreasMergerMixin } from '@md/utils/slot-areas';

const currentSlotAreas = {
  dialogLabels: ['connection-error-label'],
  dialogWindows: ['connection-error-window']
};

export default {
  mixins: [SlotAreasMergerMixin, MessagingInjectorMixin, FeedEventsHandlerMixin],

  currentSlotAreas,

  feedHandlers: [
    { event: 'connection:offline', handler() { this.openWindow(); } },
    { event: 'connection:online', handler() { this.closeWindow(); } }
  ],

  props: ['wrappedComponent'],

  data() {
    return {
      windowOpened: false
    };
  },

  methods: {
    openWindow() {
      this.$refs.window.open();
    },

    closeWindow() {
      this.$refs.window.close();
    }
  }
};

</script>
