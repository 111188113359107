import { thresholdCheck } from '@md/utils/number';

export const typerHandlers = [
  {
    event: 'self:start',
    handler() {
      this.sendEvent('typing:start', {
        chatId: this.chat.id,
        authorId: this.userId
      });
    }
  },

  {
    event: 'self:stop',
    handler() {
      this.sendEvent('typing:stop', {
        chatId: this.chat.id,
        authorId: this.userId
      });
    }
  }
];

export const feedHandlers = [
  {
    event: 'message:send',
    handler(event) {
      const message = event.body;

      if (message.chatId === this.chat.id) {
        if (this.messages.data.findIndex(x => x.id === message.id) !== -1) {
          return;
        }

        this.messages.data.push(message);

        if (message.authorId !== this.userId) {
          if (this.visibility.visible) {
            this.messageStatus('read', message);
          } else {
            this.messageStatus('received', message);
          }
        }
      }
    }
  },
  {
    event: 'message:send',
    handler() {
      if (thresholdCheck(
        this.threshold,
        this.scroller.last.height - this.scroller.element.offsetHeight,
        this.scroller.last.top
      )) {
        this.$nextTick(() => this.scroller.toBottom());
      }
    }
  },
  {
    event: 'message:update',
    handler(event) {
      if (event.body.chatId === this.chat.id) {
        const index = this.messages.data.findIndex(
          x => x.id === event.body.id
        );

        if (index !== -1) {
          this.messages.data.splice(index, 1, event.body);
        }
      }
    }
  }
];
