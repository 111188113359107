import { mergeContext } from "@aspectus/vue-utils"

const b = "checker-wrapper"

export default {
  name: b,
  functional: true,
  props: {
    multiple: {
      default: false,
      type: Boolean,
    },
    checked: { type: Boolean, default: false },
  },
  render(h, context) {
    const {
      checked,
    } = context.props;

    return (
      <label {...mergeContext({
        class: [
          b, context.class, context.data.staticClass,
          'control-input', 'control-input--styling_default', 'control-input--size_md',
          {
            'is-checked': !!checked,
          },
        ],
      }
      )}>
        <div class="control-input__element control-input__element--primary">
          <row space={"sm"} appearance="nowrap">
            <cell cols="narrow">
              {context.props.multiple ? (
                <control-checkbox {...mergeContext(context.data, context.props)} checked={checked}></control-checkbox>
              ) : (
                  <control-radio type='checkbox' {...mergeContext(context.data, context.props)} checked={checked}></control-radio>
                )}
            </cell>
            {context.children}
          </row>
        </div>
      </label>
    )
  },
}
