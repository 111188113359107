import { MessageComponentMixin } from '../../mixins';

/**
 * Component to display message on the 'text' type.
 *
 * **USED INTERNALLY**
 *
 * @version 0.1.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 */
export default {
  mixins: [MessageComponentMixin],

  bem: {
    block: 'wds-chat-message-text',
    modifierProps: ['alignment', 'devoted', 'type']
  }
};
