import { defaultDataTransformer } from '@aspectus/resource-commons';
import { jsonRequestMiddleware } from '@aspectus/resource';
import { createResource, createSender, createReceiver, createFileSender } from '@md/resources';
import { prefixAPI } from '@md/api';


export const userLoginResource = createSender(prefixAPI('/auth/login/'));
export const userRegisterResource = createSender(prefixAPI('/auth/registration/'));
export const userUpdateResource = createReceiver(prefixAPI('/user/update/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'PUT');

export const userPartialUpdateResource = createReceiver(prefixAPI('/user/update/'))
  .middleware(jsonRequestMiddleware)
  .config('method', 'PATCH');
export const passwordResetResource = createSender(prefixAPI('/auth/password/reset/'));
export const passwordConfirmResource = createSender(prefixAPI('/auth/password/reset/confirm/'));
export const passwordChangeResource = createSender(prefixAPI('/auth/password/change/'));

export const statusFilesUploadResource = createFileSender(prefixAPI('/user/status-file/upload/'))
  .transform(defaultDataTransformer);
export const statusFilesDeleteResource = createResource(prefixAPI('/user/status-file{/id}/delete/'))
  .config('method', 'DELETE');


export const coreStatusFilesUploadResource = createFileSender(prefixAPI('/user/user-status-file/upload/'))
  .transform(defaultDataTransformer);
export const coreStatusFilesDeleteResource = createResource(prefixAPI('/user/user-status-file{/id}/delete/'))
  .config('method', 'DELETE');

export const documentFilesUploadResource = createFileSender(prefixAPI('/user/document-file/upload/'))
  .transform(defaultDataTransformer);
export const documentFilesChangeResource = createSender(prefixAPI('/user/document-file{/id}/change/'))
  .transform(defaultDataTransformer)
  .config('method', 'PUT');
export const documentFilesDeleteResource = createResource(prefixAPI('/user/document-file{/id}/delete/'))
  .config('method', 'DELETE');
