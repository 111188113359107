export default {
  data() {
    return {
      data: {},
      submitting: false,
    };
  },
  methods: {
    collectFields(fields = []) {
      if (!fields || !fields.length) {
        return this.data;
      }

      return fields.reduce((acc, x) => {
        acc[x] = this.data[x];

        return acc;
      }, {});
    },
    catchFormErrors(promise) {
      if (!this.updateValidator) {
        return promise;
      }

      return promise
        .then(response => {
          this.updateValidator();

          return response;
        })
        .catch(e => {
          console.warn(e);
          if (!e.status || e.status < 400 || e.status >= 500) {
            throw e;
          }

          return e.json().then(body => {
            const errors = {};

            body.errors.forEach(error => {
              if (error.domain === 'request' && error.state) {
                Object.assign(errors, error.state);
              }
            });

            this.updateValidator(errors);

            return e;
          });
        });
    },
    submit(fields = [], additional = {}) {
      this.submitting = true;

      return this.catchFormErrors(this.send(
        Object.assign(this.collectFields(fields), additional)
      ))
        .catch(e => {
          this.$toast.error(this._('Submission failed!'));

          throw e;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
