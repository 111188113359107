<template lang="pug">

component(
  :is="wrappedComponent",
  v-bind="injectSlots($attrs)",
  v-on="$listeners",
  :slotAreas="slotAreasMerged"
)
  template(slot="emoji-picker-clicker", slot-scope="clicker")
    slot(name="emoji-picker-clicker")
      button.stacked-elements__element.control-button(
        @click.prevent="toggleWindow"
      )
        icon(name="mood")

  template(slot="field", slot-scope="field")
    textarea.stacked-elements__element.stacked-elements__element--bound_start.control-input(
      ref="field",
      :value="field.text",
      @input="field.handler($event.target.value)"
      @keyup="field.typingHandler",
      @keyup.ctrl.enter="field.sendHandler",
      rows="1"
    )

  template(slot="emoji-picker-window", slot-scope="window")
    wds-dialog-window(
      ref="window",
      dismissible="",
      :opened="window.opened",
      @input="window.handler"
    )
      slot(name="emoji-picker")
        wds-emoji-picker(style="height: 200px", @input="addEmoji")

  slot(:name="slot", :slot="slot", v-for="slot in namedSlots()")

</template>

<script>

import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';
import { SlotAreasMergerMixin } from '@md/utils/slot-areas';

const currentSlotAreas = {
  clickers: ['emoji-picker-clicker'],
  dialogWindows: ['emoji-picker-window']
};

export default {
  mixins: [SlotAreasMergerMixin, MessagingInjectorMixin],

  currentSlotAreas,

  props: ['wrappedComponent'],

  methods: {
    toggleWindow() {
      this.$refs.window.toggle();
    },

    addEmoji(item) {
      const field = this.$refs.field;
      const position = field.selectionEnd;
      const value = field.value;

      field.value = value.slice(0, position) + item + value.slice(position);
      field.dispatchEvent(new Event('input'));
    }
  }
};

</script>
