<template lang="pug">

include /mixins.pug

+b.g-row.--space_sm.--appearance_nowrap
  cell(cols="auto")
    vue-dropzone(
      @drop="$event.accepted && $emit('input', $event.accepted[0])",
      v-slot="{ open }"
    )
      +b.ds-link.--appearance_block: row(space="sm", @click="open()", appearance="nowrap")
        cell(cols="narrow"): icon(name="attach_file", size="xl")
        cell(cols="auto", v-if="value") {{ value && value.name }}
        cell(cols="auto", v-else="") {{ label ? label : _('Прикрепить файл') }}
  cell(cols="narrow", v-if="value")
    +b.ds-link.--appearance_block.--styling_error(@click="$emit('input', null)")
      icon(name="close", size="xl")

</template>

<script>

export default {
  name: 'control-file',
  props: {
    value: {},
    label: {},
  },
};

</script>
