import AuthPlugin from '@md/auth';

import Plugin from './components';

function install(Vue) {
  Vue.use(AuthPlugin);
  Vue.use(Plugin);
}

export default { install };
