import { renderSlim } from '@aspectus/vue-utils';

export default {
  name: 'spacer',
  props: {

  },
  render(h) {
    const nodes = this.$scopedSlots.default({})
    return (
      <ds-section variant='spacer' space='none'>
        <ds-section-element>
          {renderSlim(nodes, h, 'tag')}
        </ds-section-element>
      </ds-section>
    );
  }
}