<template lang="pug">

include /mixins.pug

+b.file-uploader
  dropzone-uploader(
    :resource="uploadResource",
    @upload="uploadHandler",
    v-bind="$attrs",
    :config="config",
    @drop='drop'
    ref="uploader"
  )
    template(#cover)
      +e.cover
        ds-caption(size="5") {{ _('Можно отпускать.') }}

    template(#default="uploader")
      slot(
        :open="open", :remove="remove", :uploader="uploader", :value="value"
      )
        +e.body(@click="open")
          +e.hint
            ds-caption(size="5") {{ _('Перенесите файлы сюда, или кликните для загрузки.') }}
          +e.files
            row(appearance="spaced")
              cell(:cols="cols", v-for="file in value", :key="file.id", @click.stop="")
                file-preview(
                  :url="file.file", :title="file.title", :mime="file.mime"
                )
                  template(#action)
                    ds-inliner(size="3xl")
                      control-button(styling="danger", @click="remove(file)", size="xs")
                        icon(name="close", size="lg")

              cell(:cols="cols", v-for="file in uploader.accepted", :key="file.id", @click.stop="")
                file-preview(
                  :title="file.file.name",
                  :mime="file.file.type",
                  :progress="file.progress.relative"
                )
                  template(#action)
                    ds-inliner(size="3xl", v-if="file.status === 'ready'")
                      control-button(styling="primary", @click="uploader.upload(file)", size="xs")
                        icon(name="cloud_upload", size="lg")
                    ds-inliner(size="3xl", v-if="file.status === 'process'")
                      control-button(styling="danger", @click="uploader.remove(file)", size="xs")
                        icon(name="close", size="lg")

</template>

<script>
import accepts from "attr-accept";
import { filesUploadResource } from "@md/files/api";

export default {
  name: "file-uploader",
  uploadResource: filesUploadResource,
  props: {
    self: {
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    uploadResource: {
      default: () => filesUploadResource,
    },
    accept: String,
    cols: {
      type: String,
      default: "12 6-sm 4-md 3-lg",
    },
    handler: {
      type: Function
    },
    file: {
      type: Object
    }
  },
  computed: {
    config() {
      return { acceptor: this.acceptor };
    },
  },
  methods: {
    uploadHandler({ result, descriptor }) {
      console.log(result)
      this.$refs.uploader.remove(descriptor);
      if (this.self) {
        const index = this.value.findIndex(
          (x) => x.description === descriptor.result.description
        );
        console.log(this.value[index]);
        const item = Object.assign(result, this.value[index]);
        this.value.splice(index, 1, item);
      } else {
        this.input(this.value.concat([result]));
      }
    },
    input(data) {
      this.$emit("input", data);
    },
    open() {
      this.$refs.uploader.open();
    },
    drop(e) {
      console.log(e.accepted)
      if (this.self) {
        this.$refs.uploader.upload(this.handler(this.$refs.uploader.makeFile(e.accepted[0], 'ready'), this.file.title), {description: this.file.description })
      } else {
        const { accepted, rejected } = e
        const files = accepted.map(file => this.$refs.uploader.makeFile(file, 'ready'));
        const failed = rejected.map(file => this.$refs.uploader.makeFile(file, 'failure'));
        this.$refs.uploader.rejected = this.$refs.uploader.rejected.concat(failed);
        this.$refs.uploader.accepted = this.$refs.uploader.accepted.concat(files);
        
        failed.forEach(this.$refs.uploader.reject);
        if (this.$refs.uploader.autoload) {
          files.forEach(this.$refs.uploader.upload);
        }
      }
    },
    remove(file) {
      console.log(file)
      const { id } = file;

      const index = this.value.findIndex((x) => x.id === id);
      console.log(index);
      if (index !== -1) {
        if (this.self) {
          this.$refs.uploader.accepted = []
          console.log(this.value);
          const { title, description } = this.value[index];
          // this.input(
            this.value.splice(
              index,
              1,
              Object.assign({}, { title, description })
            )
          // );
        } else {
          this.input(
            this.value.slice(0, index).concat(this.value.slice(index + 1))
          );
        }
      }
    },
    acceptor(file) {
      return this.accept ? accepts(file, this.accept) : true;
    },
  },
};
</script>
