/**
 * Dialog window component.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsDialogWindow',

  props: {
    dismissible: Boolean,
    opened: Boolean
  },

  watch: {
    opened: {
      immediate: true,
      handler(value, old) {
        if (value !== old) {
          this.$emit(value && 'open' || 'close');
        }
      }
    },
  },

  methods: {
    open() {
      this.$emit('input', true);
    },

    toggle() {
      this.$emit('input', !this.opened);
    },

    close() {
      this.$emit('input', false);
    }
  }
};
