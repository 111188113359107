// import { createRouter } from '@/router';
import { createI18n } from '@/i18n'
// import { createStoreObject } from '@/store';
import { createGlobalsMixin } from '@md/global-definer';
import Cookies from 'js-cookie';
import addYears from "date-fns/addYears";
import isBefore from "date-fns/isBefore";

function getQueryParams() {
  let out = {};
  document.location.search.slice(1).split('&').map(el => {
    let p = decodeURI(el).split('=');
    out[p[0]] = p[1];
  });
  return out;
}

function userInitializer(accessor) {
  accessor.IS_EMPLOYEE = accessor.GET('profile', 'type') === 'employee'
  accessor.IS_EMPLOYER = accessor.GET('profile', 'type') === 'employer'
  accessor.IS_ACTIVE = accessor.GET('isActive')
  accessor.IS_UNLOCKED = accessor.IS_ACTIVE && (accessor.IS_EMPLOYER || accessor.GET('profile', 'status') === '00100-active')
}

function respondedJobsInitializer(accessor) {
  accessor.HAS = id => {
    const value = accessor.GET()
    return value && value.includes(id)
  }
}

const setProperDate = value => {
  return value < 10 && value.length < 2 ? `0${value}` : value
}

export function createApp({ Vue, Router, Store, I18n }) {
  // const store = createStoreObject({ Vue, Store });
  const i18n = createI18n({ Vue, I18n })
  // const router = createRouter({ Vue, Router, store });

  const globalLoaderBus = new Vue()

  Vue.mixin(createGlobalsMixin('USER', window.AUTH_USER, userInitializer))
  Vue.mixin(createGlobalsMixin('RESPONDEND_JOBS', window.USER_RESPONDED_JOBS, respondedJobsInitializer))

  Vue.prototype.$GL = value => globalLoaderBus.$emit('change', value)

  Vue.prototype.$localizeDate = value => {

    console.log(value)
    const [month, day, year] = value.replace(/\//g, '.').split('.')

    if (window.document.documentElement.lang.includes('en')) {
      return `${setProperDate(day)}.${setProperDate(month)}.${year}`
    } else {
      return `${setProperDate(month)}.${setProperDate(day)}.${year}`
    }
  }
  
  Vue.prototype.$GO = function (url) {
    // this.$GL(true);
    window.location.assign(url)
  }
  Vue.prototype.$captchaKey = window.captchaKey
  Vue.prototype.$copyInputText = function (input) {
    if (input.tagName.toLowerCase() !== 'input') {
      input = input.querySelector('input')
    }

    input.select()
    input.setSelectionRange(0, 99999)

    document.execCommand('copy')
    this.$toast.success(this._('Текст скопирован.'))
  }

  Vue.directive('gl-click', {
    bind(el, binding, vnode) {
      // el.addEventListener('click', () => vnode.context.$GL(true));
    },
  })

  const app = new Vue({
    i18n,
    // router,
    // store,
    data() {
      return {
        type: null,
        globalLoader: true
      }
    },
    mounted() {
      // this.$set(this.globalLoader, 'value', false)
      document.getElementById('globalLoader').remove()
      console.log('changed on load')
      globalLoaderBus.$on('change', value => {
        this.globalLoader = value
      })
    },

    methods: {
      redirect(url, isCheckQueryRedirect = false) {
        if (isCheckQueryRedirect) {
          let p = getQueryParams();
          if (p.redirect_to) url = p.redirect_to;
        }
        window.location.assign(url);
      },
      hideAdv() { document.getElementById('a-banner-message').style.display = 'none'; },
      referralIsShowTip() {
        let joinedDate = new Date(this.USER.GET('dateJoined')),
            yearBefore = addYears(new Date, -1);
        return !isBefore(joinedDate, yearBefore) && !Cookies.get('timework-referral-showed-tip');
      },
      referralSetTipCockie() {
        Cookies.set('timework-referral-showed-tip', '1', {expires: 90})
      },
    },
  })

  return { app, i18n }
}

export {
  // createRouter,
}

window.download = async (url) => {
  const myWindow = await window.open(url, '_blank')
  await myWindow.focus()
  myWindow.onload = myWindow.print;
  
}