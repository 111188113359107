<template lang="pug">

include /mixins.pug

validation-observer(v-slot="observer", ref="validator", slim)
  slot(
    :observer="observer",
    :submit="createSubmit(observer)",
    :reset="reset",
    :createSubmit="createSubmit",
    :send="send",
    :log="log",
    :options="options",
    :submitting="submitting",
    :data="data",
    :result="result",
    :globalErrors="nonFieldErrors",
    :idGetter="idGetter",
    :contracts="contracts",
    :specializations="specializations",
  )

</template>

<script>

import { path, clone, pipe, assocPath } from 'ramda';
import SimpleForm from '@md/ui/components/SimpleForm.vue';

const DATE_FOR_TIME = new Date(0).toISOString().split('T')[0];
const tTime = v => (v ? new Date(DATE_FOR_TIME + 'T' + v) : null);
const fTime = v => (v && (new Date(v - v.getTimezoneOffset() * 60 * 1000)).toISOString().slice(11, 19) || null);
const tDate = v => (v ? new Date(v) : null);
const dateToISO = d => {
    let date = d.getDate(),
	month = d.getMonth() + 1,
	year = d.getFullYear();
	return `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date}T00:00:00.000Z`;
};
const fDate = v => {
    return (v && dateToISO(v));
};
const tCustom = (v, options) => {
  if (!(v && v.id)) {
    return null;
  }

  let current = options.find(x => x.id === v.id);

  if (!current) {
    current = options[options.length - 1];
    current.data = v;
  }

  return current;
};
const fCustom = v => (v && v.id === 'custom' ? v.data : v);
const tTimeObject = v => (v && {
  employmentRange: {
    min: tDate(v.employmentAt), max: tDate(v.dismissalAt),
  },
  worktimeRange: {
    min: tTime(v.worktimeStartsAt), max: tTime(v.worktimeEndsAt),
  },
  hourlyPayment: v.hourlyPayment || '',
});
const fTimeObject = v => (v && {
  employmentAt: fDate(v.employmentRange.min),
  dismissalAt: fDate(v.employmentRange.max),
  worktimeStartsAt: fTime(v.worktimeRange.min),
  worktimeEndsAt: fTime(v.worktimeRange.max),
  hourlyPayment: v.hourlyPayment || 0,
});
const makeCustom = title => ({ id: 'custom', title, data: { title: '' } });
const addCustom = (list, title) => (list || []).concat([makeCustom(title)]);

export default {
  extends: SimpleForm,
  name: 'jobs-edit-form',

  data() {
    return {
      contracts: addCustom(this.options.contracts, this._('Другой')),
      specializations: addCustom(this.options.specializations, this._('Свой вариант')),
    };
  },

  methods: {
    idGetter(value) {
      return value.id;
    },
    transformTo(data) {
      let result = clone(data);

      result.contract = tCustom(result.contract, this.contracts);
      result.specialization = tCustom(result.specialization, this.specializations);
      result.time = (data.time || []).map(tTimeObject);
      result.address = result.data ? result.data.address : undefined;
      result.location = result.location ? result.location : result.address ? {
        latitude: result.address.point.lat,
        longitude: result.address.point.lng
      } : undefined;
      result = this.injectUserData(result);
      delete result.data;

      return result;
    },
    transformFrom(data) {
      let result = clone(data);

      result.contract = fCustom(result.contract);
      result.specialization = fCustom(result.specialization);
      result.time = (data.time || []).map(fTimeObject);
      result.data = {
          address: {...result.address},
      };
      result.location = {
          latitude: result.address.point.lat,
          longitude: result.address.point.lng
      };
      delete result.address;

      return result;
    },

    go(url) {
      this.$GO(url.replace('/0/', `/${this.result.id}/`));
    },

    injectUserData(src) {
        let address = this.USER.GET('profile', 'data', 'address');
        if (src.address === undefined) {
            src.address = address;
        }
        if (src.location === undefined) {
            src.location = {
                latitude: address.point.lat,
                longitude: address.point.lng
            }
        }
        return src;
    },
  },
};

</script>
