import { renderSlim, isEmpty } from '@aspectus/vue-utils';

const R = 6371e3; // Earth radius in meters
const RAD = Math.PI / 180;

function getCircularDistance(from, to) {
  // Formula for circular distance calculation
  // a = sin²(Δφ / 2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ / 2)
  // c = 2 ⋅ atan2(√a, √(1−a))

  const phi1 = from.lat * RAD;
  const phi2 = to.lat * RAD;
  const dPhi = (to.lat - from.lat) * RAD;
  const dLambda = (to.lng - from.lng) * RAD;

  const a = Math.sin(dPhi / 2) ** 2 + Math.cos(phi1) * Math.cos(phi2) * Math.sin(dLambda / 2) ** 2;

  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

function getDistance(from, to, radius = R) {
  // d = R ⋅ c
  return radius * getCircularDistance(from, to);
}

export default {
  name: 'simple-distance-calculator',
  computed: {
    distance() {
      const { from, to } = this;

      if (isEmpty(from) || isEmpty(to)) {
        return null;
      }

      return getDistance(from, to);
    },
  },
  render(h) {
    const nodes = this.$scopedSlots.default({ distance: this.distance });

    return nodes ? renderSlim(nodes, h) : null;
  },
  props: ['from', 'to'],
};
