<template functional lang="pug">

include /mixins.pug

+b.SVG.loading(
  v-bem:loading="{ size: props.size }"
  v-bind="data.attrs"
  v-on="data.on"
  viewBox="-1 -3 18 18"
  preserveAspectRatio="xMidYMid"
)
  +e.POLYLINE.background(
    fill="none",
    points="1 6 4 6 6 11 10 1 12 6 15 6"
  )
  +e.POLYLINE.foreground(
    fill="none",
    points="1 6 4 6 6 11 10 1 12 6 15 6"
    stroke-dasharray="12, 36"
    stroke-dashoffset="48"
  )
    animate(
      attributeName="stroke-dashoffset"
      :dur="props.duration"
      repeatCount="indefinite"
      from="48"
      to="0"
    )

</template>

<script>

export default {
  name: 'loading',
  props: {
    size: { default: 'md' },
    duration: { default: '1s' },
  },
};

</script>
