import { renderSlim } from '@aspectus/vue-utils';
import debounce from 'lodash.debounce';

export default {
  name: 'delayed-executor-controller',

  props: {
    delay: {
      type: Number,
      default: 0,
    },
    receiver: {
      type: Function,
      required: true,
    }
  },

  computed: {
    receive() {
      if (this.delay === 0) {
        return this.receiver;
      }

      return debounce(this.receiver, this.delay);
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      receive: this.receive,
    }), h, 'tag');
  },
};
