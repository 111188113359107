import Vue from 'vue'

import Audio from './components/Audio/Audio';
import List from './components/List/List';
import Message from './components/Message/Message';
import MessagesGroup from './components/MessagesGroup/MessagesGroup';
import Preview from './components/Preview/Preview';
import Conversation from './components/Conversation/Conversation';
import Room from './components/Room/Room';
import Provider from './components/Provider/Provider';

import File from './components/Message/types/File/File.vue';
import Text from './components/Message/types/Text/Text.vue';

import Handler from './components/FileUpload/components/Handler/Handler';
import Trigger from './components/FileUpload/components/Trigger/Trigger';

import DialogSet from './components/dialog/Set/Set'
import Window from './components/dialog/Window/Window'

import Media from './components/Media/Media'

import ProgressBar from './components/ProgressBar/ProgressBar'

import feedTypingHandler from './utils/hocs/feedTypingHandler';
import feedChatListHandler from './components/List/hocs/feedChatListHandler';
import fileUploaderWrapper from './components/Room/hocs/fileUploaderWrapper';
import emojiPickerWrapper from './components/Room/hocs/emojiPickerWrapper';

import EmojiPickerWrapper from './components/Room/wrappers/EmojiPickerWrapper';
import FileUploaderWrapper from './components/Room/wrappers/FileUploaderWrapper';

import { DataFeedInterface } from './DataFeed/Interface';
import MessagingService from './services/Messaging';

import FeedEventsHandlerMixin from './utils/mixins/FeedEventsHandlerMixin';
import FeedTypingHandler from './utils/mixins/FeedTypingHandler';
import LauncherMixin from './utils/mixins/LauncherMixin';
import MessagesReceiverMixin from './utils/mixins/MessagesReceiverMixin';
import MessagingInjectorMixin from './utils/mixins/MessagingInjectorMixin';
import TyperEventsHandlerMixin from './utils/mixins/TyperEventsHandlerMixin';

const DEFAULT_COMPONENT_PREFIX = 'Wds';

// Exporting Components
export {
  Provider, Room, Conversation, Preview, MessagesGroup, Message, List, Audio
};

// Exporting internal Components
export {
  File, Text
};

// Exporting HOCs
export {
  feedTypingHandler, feedChatListHandler, fileUploaderWrapper, emojiPickerWrapper
};

// Exporting Wrappers
export {
  FileUploaderWrapper, EmojiPickerWrapper
};

// Exporting utility classes/services
export {
  DataFeedInterface, MessagingService
};

// Exporting mixins
export {
  FeedEventsHandlerMixin, FeedTypingHandler, LauncherMixin,
  MessagesReceiverMixin, MessagingInjectorMixin, TyperEventsHandlerMixin
};

export function install(Vue, {
  сomponentsPrefix = DEFAULT_COMPONENT_PREFIX
} = {}) {

  Vue.component(Handler.name, Handler)
  Vue.component(Trigger.name, Trigger)

  Vue.component(DialogSet.name, DialogSet)
  Vue.component(Window.name, Window)

  Vue.component(Media.name, Media)

  Vue.component(ProgressBar.name, ProgressBar)



  const replacer = new RegExp(`^${DEFAULT_COMPONENT_PREFIX}`);
  const registerComponent = Component => {
    Vue.component(Component.name.replace(replacer, сomponentsPrefix), Component);
  };

  [
    Provider,
    Room,
    Conversation,
    Preview,
    MessagesGroup,
    Message,
    List,
    Audio
  ].forEach(registerComponent);
}
