import BemComponentMixin from '../../../utils/bem/BemComponentMixin';

/**
 * Avatar component.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'Avatar',
  mixins: [BemComponentMixin],

  bem: {
    block: 'c-avatar',
    modifierProps: ['size']
  },

  props: {
    /**
     * Avatar size name.
     */
    size: {
      type: String,
      default: null
    },
    /**
     * Avatar image url.
     */
    image: {
      type: String,
      default: null
    }
  }
};
