import { subscribe, unsubscribe } from '../subscribe';

export default {
  feedHandlers: [],

  watch: {
    feed: {
      immediate: true,
      handler(value, old) {
        if (value !== old) {
          this.unsubscribeFeed(old);
          this.subscribeFeed(value);
        }
      }
    }
  },

  activated() {
    this.subscribeFeed();
  },

  deactivated() {
    this.unsubscribeFeed();
  },

  beforeDestroy() {
    this.unsubscribeFeed();
  },

  methods: {
    subscribeFeed(feed = this.feed) {
      if (!feed) return;

      this.$options.feedHandlers = subscribe(
        feed.emitter, this.$options.feedHandlers, this
      );
    },

    unsubscribeFeed(feed = this.feed) {
      if (!feed) return;

      unsubscribe(feed.emitter, this.$options.feedHandlers);
    }
  }
};
