import pick from 'ramda/src/pick';
import { elementModifiers, elementStates } from './functions';

/**
 * @mixin
 */
export default {
  bem: {
    block: null,
    modifierProps: []
  },

  props: {
    /**
     * List of applied states to a component.
     */
    states: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    $blockClasses() {
      const opts = this.$options.bem;
      return this.$elementClasses(
        opts.block, pick(opts.modifierProps || [], this), this.states
      );
    }
  },

  methods: {
    /**
     * Method to construct state classes.
     *
     * @param {Array} states List of state names.
     * @returns {Array} List of state classes.
     * @public
     */
    $stateClasses: elementStates,

    /**
     * Method to construct element modifier classes.
     *
     * @param {String} element Element class name.
     * @param {Array} modifiers Map of modifier->value pairs.
     * @returns {Array} List of modifier classes.
     * @public
     */
    $modifierClasses(element, modifiers) {
      if (!element) return [];

      return elementModifiers(element, modifiers);
    },

    /**
     * Method to construct element classes.
     *
     * @param {String} element Element class name.
     * @param {Array} modifiers Map of modifier->value pairs.
     * @param {String} states States, that needs to be applied.
     * @returns {Array} List of modifier/state classes.
     * @public
     */
    $elementClasses(element, modifiers = {}, states = []) {
      return [].concat(
        this.$modifierClasses(element, modifiers),
        this.$stateClasses(states)
      );
    }
  }
};
