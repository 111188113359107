import Text from './Text/Text.vue';
import textText from './Text/text';

import File from './File/File.vue';
import textFile from './File/text';

export default {
  text: {
    text: textText,
    component: Text
  },

  file: {
    text: textFile,
    component: File
  }
};
