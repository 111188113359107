const loadGoogleMapsApi = require('load-google-maps-api');

export const API_BASE_URL = '/api/v1';
export const prefixAPI = url => API_BASE_URL + url;

export const LOGOUT_URL = '/auth/logout/';

export const maps = () => loadGoogleMapsApi({
  key: window.CONFIG.GOOGLE_API_KEY,
  libraries: ['places'],
  language: 'de',
});
