import { makeF, mergeContext } from '@aspectus/vue-utils';

export default makeF(
  (h, context) => h(
    'simple-distance-calculator',
    mergeContext(
      context.data,
      {
        props: {
          to: context.props.to, from: context.parent.USER.GET('profile', 'data', 'address', 'point'),
        },
      }
    ),
    context.children
  ),
  ['to'],
  'user-distance-calculator'
);
