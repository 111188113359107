import { mergeContext } from '@aspectus/vue-utils';

const b = 'control-checkbox';

export default {
  name: b,
  functional: true,

  render(h, context) {
    return h(
      'control-checker',
      mergeContext(context.data, { attrs: { b } }),
      context.children
    );
  },
};
