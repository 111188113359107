export function mergeAreas(source, required) {
  const areas = Object.assign({}, source);

  Object.keys(required).forEach(key => {
    if (!areas[key]) {
      areas[key] = [];
    }

    areas[key] = areas[key].concat(
      required[key].filter(slot => !areas[key].includes(slot))
    );
  });

  return areas;
}

/**
 * Passed slots mixin
 * @mixin
 */
export const PassedSlots = {
  methods: {
    namedSlots() {
      return Object.keys(this.$slots);
    },

    injectSlots(attrs) {
      return {
        ...(attrs || {}),
        $scopedSlots: this.$scopedSlots
      };
    }
  }
};

/**
 * Slot area mixin
 * @mixin
 */
export const SlotAreasMixin = {
  mixins: [PassedSlots],

  props: {
    slotAreas: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    hasSlot(name, includeScoped = true) {
      return this.$slots[name] || (includeScoped && this.$scopedSlots[name]);
    },

    getSlotNames(areas) {
      return Array.prototype.concat.apply(
        [], areas.map(name => this.slotAreas[name]).filter(x => !!x)
      );
    }
  }
};

export const SlotAreasMergerMixin = {
  mixins: [SlotAreasMixin],

  currentSlotAreas: {},

  computed: {
    slotAreasMerged() {
      return mergeAreas(this.slotAreas, this.$options.currentSlotAreas);
    }
  }
};
