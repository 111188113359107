<template functional lang="pug">

include /mixins.pug

+b.TAG.control-input(
  v-bem:control-input="{ styling: props.styling, size: props.size, type: props.type }"
  v-state="{ readonly: props.readonly, invalid: props.invalid }"
  :class="[data.staticClass, data.class]"
  :tag="props.tag"
)
  input.control-input__element.control-input__element--input.control-input__element--primary(
    :type="props.type",
    :value="props.value",
    :readonly="props.readonly",
    v-bind="data.attrs",
    v-on="{ ...data.on, input: e => (data.on && data.on.input && data.on.input(e.target.value)) }"
    v-if="!props.mask"
  )
  +e.THE-MASK.element.--input.--primary(
    :type="props.type",
    :value="props.value",
    :readonly="props.readonly",
    :mask="props.mask",
    masked="",
    v-bind="data.attrs",
    v-on="data.on"
    v-else
  )
  +e.element.--append(v-if="props.icon || scopedSlots.icon")
    slot(name="icon")
      icon(:name="props.icon")

</template>

<script>

export default {
  name: 'control-input',
  props: {
    value: { },
    size: { default: 'md' },
    styling: { default: 'default' },
    type: { default: 'text' },
    readonly: Boolean,
    invalid: Boolean,
    tag: { default: 'label' },
    mask: String,
    icon: {},
  },
};

</script>
