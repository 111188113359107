import { snakelize } from '@md/strings/transformers/case'

function filterEntries(items) {
  return Object.entries(items).map(([key, value]) => {

    if (Array.isArray(value)) {
      return value.length > 0
    } else if (value && typeof value === 'object') {
      return filterEntries(value)
    }
    return value
  })
}
export default {
  name: 'user-filter',
  props: {
    filter: {
      required: false,
      type: Object,
    },

  },
  data() {
    return {
      transformedFilter: null
    }
  },
  computed: {
    hasValues() {
      return this.transformedFilter && !!filterEntries(this.transformedFilter).flat(Infinity).filter(i => i).length
    }
  },
  methods: {
    save(filterObject) {
      const user = window.AUTH_USER
      user.profile.data.filters = filterObject ? filterObject.value : {}
      this.$api.auth.user.update.execute({}, user).then(res => {
        this.transformedFilter = JSON.parse(JSON.stringify(this.transformFrom(filterObject.value)))
        this.$emit('finish', { context: this })
        this.$toast.success(this._('Информация успешно обновлена.'))
      }).catch(err => this.$emit('fail', { context: this }))
    },
    async activate(filterObject) {
      filterObject.apply(JSON.parse(JSON.stringify(this.transformedFilter)))
    },
    transformFrom(filter) {
      return Object.keys(filter).reduce((acc, key) => {
        acc[snakelize(key)] = filter[key]
        return acc
      }, {})
    }
  },
  mounted() {
    this.transformedFilter = this.transformFrom(this.filter)

  },
  render(h) {
    return this.$scopedSlots.default({
      filter: this.filter,
      save: this.save,
      activate: this.activate,
      hasValues: this.hasValues
    })
  }
}