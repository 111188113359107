<template functional lang="pug">

include /mixins.pug

+b.TAG.control-button(
  v-bem:control-button="{ variant: props.variant, styling: props.styling, size: props.size, type: props.type, appearance: props.appearance }",
  v-state="{ disabled: props.disabled, invalid: props.invalid }",
  :disabled="props.disabled",
  v-bind="data.attrs",
  v-on="data.on",
  :class="[data.staticClass, data.class]",
  :tag="props.tag",
  :type="props.type",
)
  +e.element.--primary
    row(:appearance="['inline', 'nowrap']", space="xs")
      cell(v-if="props.icon || scopedSlots.icon")
        slot(name="icon")
          icon(:name="props.icon", size="xl")
      cell(v-if="scopedSlots.default")
        slot

</template>

<script>

export default {
  name: 'control-button',
  props: {
    size: { default: 'md' },
    appearance: {},
    styling: { default: 'primary' },
    variant: { default: 'default' },
    type: { default: 'button' },
    tag: { default: 'button' },
    icon: {},
    disabled: Boolean,
    invalid: Boolean,
  },
};

</script>
