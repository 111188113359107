<script>
export default {
  name: 'file-downloader',
  methods: {
    download(url) {
      console.log(url)
      const myWindow=window.open(url,'_blank');
      // myWindow.document.close(); //missing code
      myWindow.focus();
      myWindow.print(); 
    }
  },
  render() {
    return this.$scopedSlots.default({
      download: this.download
    })
  }
}
</script>