import { createHOC } from 'vue-hoc';

import FailedMessagesHandlerWrapper from '../wrappers/FailedMessagesHandlerWrapper';

export default function hoc(Component) {
  return createHOC(FailedMessagesHandlerWrapper, null, {
    props: {
      wrappedComponent: Component
    }
  });
}
