import { withDefaultProps } from '@aspectus/vue-utils';
import Descriptor from './Descriptor';
import Input from './Input';
import AddressInput from './AddressInput';
import TextArea from './TextArea';
import File from './File';
import Select from './Select';
import DatePicker from './DatePicker';

const dt = tag => withDefaultProps({ tag })(Descriptor);

export const DInput = dt(Input);
export const DAddressInput = dt(AddressInput);
export const DTextArea = dt(TextArea);
export const DFile = dt(File);
export const DSelect = dt(Select);
export const DDatePicker = dt(DatePicker);
