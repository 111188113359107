<template lang="pug">

include /mixins.pug

control-stack
  template(#prepend)
    control-date-picker(
      :placeholder="placeholderMin",
      :disabled-date="disabledFrom",
      :value="value && value.min",
      @input="input($event || null, 'min')",
      :format-name="formatName",
    )
  template(#append)
    control-date-picker(
      :placeholder="placeholderMax",
      :disabled-date="disabledTo",
      :value="value && value.max",
      @input="input($event || null, 'max')",
      :format-name="formatName",
    )

</template>

<script>

const MINUTE = 60000;
const ETERNITY = MINUTE * MINUTE * MINUTE * MINUTE;

export default {
  name: 'date-range-picker',

  props: ['min', 'max', 'placeholderMin', 'placeholderMax', 'value', 'formatName'],
  computed: {
    disabledFrom() {
      return this.disabledBoth(this.min, this.value && this.value.max || this.max);
    },
    disabledTo() {
      return this.disabledBoth(this.value && this.value.min || this.min, this.max);
    },
  },
  methods: {
    disabledBoth(from, to) {
      let pFrom = new Date(from || 0);
      pFrom.setHours(0, 0, 0, 0);

      let pTo = new Date(to || ETERNITY);
      pTo.setHours(23, 59, 59, 999);

      return date => date && ((pFrom && pFrom > date) || (pTo && pTo < date));
    },
    input(value, key) {
      this.$emit('input', Object.assign({}, this.value, { [key]: value }));
    },
  },
};

</script>
