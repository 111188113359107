<template lang="pug">
include /mixins.pug
file-downloader(v-slot='downloaderProps')
  a(@click='downloaderProps.download(file)') download
</template>

<script>
export default {
  name: 'StatefulFileDonwloader',
  props: {
    file: {
      required: true
    }
  }
}
</script>