import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';

const EventEmitter = require('events');

export function eventStartStop(
  startTimeout,
  stopTimeout = startTimeout * 2
) {
  return function wrapper(callback) {
    const start = throttle(function start() {
      callback.apply(this, ['start', ...arguments]);
    }, startTimeout);

    const end = debounce(function end() {
      callback.apply(this, ['stop', ...arguments]);
    }, stopTimeout);

    return function caller() {
      start.apply(this, arguments);
      end.apply(this, arguments);
    };
  };
}

export class TypeHandler {
  emitter = new EventEmitter();

  typing = [];

  breakers = {};

  constructor({
    startTimeout = 0,
    stopTimeout = startTimeout * 2,
    breakTimeout = stopTimeout * 3
  } = {}) {
    this.config = {
      startTimeout, stopTimeout, breakTimeout
    };

    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.typeHandler = eventStartStop(
      this.config.startTimeout, this.config.stopTimeout
    )(this.handleTyping).bind(this);
  }

  bind() {
    this.emitter.on('typing:start', this.handleStart);
    this.emitter.on('typing:stop', this.handleStop);

    return this;
  }

  unbind() {
    this.emitter = new EventEmitter();
  }

  handleTyping(type) {
    this.emitter.emit(`self:${type}`);
  }

  handleStart(typer) {
    if (!this.typing.includes(typer)) {
      this.typing.push(typer);
      this.emitter.emit('typer:added');
    }

    this.brake(typer);
  }

  handleStop(typer) {
    const index = this.typing.indexOf(typer);
    if (!index !== -1) {
      this.typing.splice(index, 1);
      this.emitter.emit('typer:removed');
    }
  }

  brake(typer) {
    if (typeof this.breakers[typer] === 'undefined') {
      this.breakers[typer] = debounce(
        this.handleStop, this.config.breakTimeout
      ).bind(this);
    }

    return this.breakers[typer](typer);
  }
}
