<template lang="pug">
include /mixins.pug
+b.file-uploader
  dropzone-uploader(
    :resource="$options.uploadResource",
    @upload="uploadHandler",
    v-bind="$attrs"
    :config="config"
    :autoload='true'
    ref="uploader"
  )
    template(#cover)
      slot(name="dropzone-cover")

    template(#default="fileUpload")
      +e.body
        component(
          ref="room",
          :is="wrappedComponent",
          v-bind="injectSlots($attrs)",
          v-on="$listeners",
          :slotAreas="slotAreasMerged",
          :chat="chat",
          :userId="userId"
        )
          template(v-slot:file-uploader-clicker="clicker")
            slot(name="file-uploader-clicker")
              button.ds-link.stacked-elements__element(
                @click.prevent="open"
              )
                svg-icon(name="attach", style='--inliner-size: 28px')

          template(v-slot:file-uploader-label="label" v-if="!windowOpened && fileProgress > 0", )
            .wds-chat-room__label(@click="openWindow")
              button.control-button.control-button--style_default.control-button--size_xs
                icon(name="cloud_upload", size="lg")
                span.tag.tag--type_error.tag--round.tag--super(v-text="fileProgress")

          template(v-slot:file-uploader-window='window')
            wds-dialog-window(
              ref="window",
              :dismissible="false",
              :opened="(fileUpload.accepted.length > 0 || fileUpload.rejected.length > 0) ?  openWindow() : closeWindow()",
              @input="window.handler",
              @open="windowOpened = true",
              @close="windowOpened = false"
            ).wds-dialog-window--file-wrapper
              slot(name="files-preview", :fileUpload="fileUpload", :removeRejected="removeRejected")

          slot(v-slot:[slot]="", v-for="slot in namedSlots()")

</template>

<script>

import { formatTranslation } from '@md/strings';
import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';
import { SlotAreasMergerMixin } from '@md/utils/slot-areas';
import { filesUploadResource } from '@md/files/api';

const currentSlotAreas = {
  clickers: ['file-uploader-clicker'],
  dialogLabels: ['file-uploader-label'],
  dialogWindows: ['file-uploader-window']
};

export default {
  currentSlotAreas,
  uploadResource: filesUploadResource,
  props: ['chat', 'userId', 'wrappedComponent'],
  mixins: [SlotAreasMergerMixin, MessagingInjectorMixin],
  data() {
    return {
      fileProgress: 0,
      windowOpened: false
    };
  },
  computed: {
    config() {
      return { acceptor: this.acceptor };
    },
  },

  methods: {
    openWindow() {
      this.$refs.window.open();
      return true
    },
    closeWindow() {
      if (this.$refs.window) {
        this.$refs.window.close();
        return false
      }
    },
    open() {
      this.$refs.uploader.open();
    },
    uploadHandler(file) {
      this.feed.sendMessage({
        type: 'file',
        authorId: this.userId,
        chatId: this.chat.id,
        body: {
          name: file.descriptor.file.name,
          size: file.descriptor.file.size,
          source: file.descriptor.result
        }
      });

      this.$refs.uploader.remove(file.descriptor);
    },
    removeRejected(fileUpload, index) {
      fileUpload.rejected.splice(index, 1);
    },
    acceptor(file) {
      if(file.size / 1024 > window.CONFIG.FILE_MAX_SIZE_UPLOAD) {
        file.error = formatTranslation(
          this._('File is too big(><[size]>)'),
          { size: this.$n(window.CONFIG.FILE_MAX_SIZE_UPLOAD / 1024, { style: 'unit', unit: 'megabyte', unitDisplay: 'short' }) }
        );

        return false;
      }

      return true;
    }
  }
};

</script>
