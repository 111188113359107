<script>
const initialIds = [1, 2, 3];
export default {
  name: "files-serializer",
  props: {
    initial: {
      required: true,
    },
  },
  data() {
    return {
      initialIds: [
        {
          _id: 1,
          title: this._("doc 1"),
        },
        {
          _id: 2,
          title: this._("doc 2"),
        },
        {
          _id: 3,
          title: this._("doc 3"),
        },
      ],
    };
  },
  computed: {
    files() {
      const files = this.initialIds.map((f) => {
        // console.log(f)
        let file = this.initial.files.find(
          (file) => JSON.parse(file.description)._id === f._id
        );

        file = {
          ...file,
          title: f.title,
          description: JSON.stringify({
            _id: f._id,
          }),
        };
        return file;
      });

      console.log(files);
      return {
        files: files,
      };
    },
    downloadedFiles() {
      return this.files.files.filter(f => f.file)
    }
  },
  methods: {
    transformTo(file, title) {
      console.log(file, title)
      return {
        file: {
          ...file.file,
          title: title,
        },
      }
    }
  },
  render() {
    return this.$scopedSlots.default({
      files: this.files,
      transformTo: this.transformTo,
      downloadedFiles: this.downloadedFiles
    });
  },
};
</script>
