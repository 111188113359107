import { SlotAreasMixin } from "@md/utils/slot-areas";

/**
 * Dialog set component. Handles dialog windows showup mechanics.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsDialogSet',

  mixins: [SlotAreasMixin],

  watch: {
    'slotAreas.windows': {
      immediate: true,
      handler: 'generateWindowHandlers'
    }
  },

  data() {
    return {
      windows: {}
    };
  },

  computed: {
    opened() {
      return Object.values(this.windows).some(x => x.opened);
    }
  },

  methods: {
    generateWindowHandlers() {
      (this.slotAreas.windows || []).forEach(slot => {
        if (this.windows[slot]) return;

        this.windows[slot] = {
          opened: false,
          handler: value => {
            this.windows[slot].opened = value;
          }
        };
      });

      this.windows = Object.assign({}, this.windows);
    },

    close() {
      Object.values(this.windows).forEach(window => {
        window.opened = false;
      });
    }
  }
};
