import pick from 'ramda/src/pick';
import BemComponentMixin from '@md/utils/bem/BemComponentMixin';

/**
 * Media.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'Media',
  mixins: [BemComponentMixin],

  bem: {
    block: 'c-media'
  },

  props: {
    /**
     * Media space size name.
     */
    space: {
      type: String,
      default: 'md'
    },
    /**
     * Media position.
     *
     * @enum {string}
     */
    position: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].includes(val)
    }
  },

  computed: {
    $blockClasses() {
      const opts = this.$options.bem;
      return [].concat(
        this.$elementClasses(
          opts.block, pick(opts.modifierProps || [], this), this.states
        ),
        this.$modifierClasses('row', pick(['space'], this))
      );
    },

    mediaClasses() {
      return this.$modifierClasses('c-media__media', pick(['position'], this));
    }
  }
};
