const EventEmitter = require('events');

export function isDragDataWithFiles(event) {
  if (!event.dataTransfer) {
    return true;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
  return Array.prototype.some.call(
    event.dataTransfer.types,
    type => type === 'Files' || type === 'application/x-moz-file'
  )
}

const toArray = iterable => Array.prototype.slice.call(iterable);

export class DropZone {
  /**
   * Dragging counter.
   *
   * @memberof DropZone
   */
  dragging = 0;

  /**
   * Responsible for DropZone event emitting.
   *
   * @memberof DropZone
   */
  emitter = new EventEmitter();

  /**
   * Elements, that are used as drop zones.
   *
   * @memberof DropZone
   */
  zones = [];

  /**
   * Input elements, to handle file imports.
   *
   * @memberof DropZone
   */
  inputs = [];

  constructor() {
    this.onDragover = this.onDragover.bind(this);
    this.onDragenter = this.onDragenter.bind(this);
    this.onDragleave = this.onDragleave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  /**
   * Binds DropZone to a particular elements.
   *
   * @param {*} zones Array of DOM elements that will be used as drop zones.
   * @param {*} inputs Array of DOM elements that will be used as file inputs.
   * @memberof DropZone
   */
  bind(zones, inputs) {
    this.zones = zones.concat(this.zones);
    this.inputs = inputs.concat(this.inputs);

    this.zones.forEach(element => {
      element.addEventListener('dragover', this.onDragover, false);
      element.addEventListener('dragenter', this.onDragenter, false);
      element.addEventListener('dragleave', this.onDragleave, false);
      element.addEventListener('drop', this.onDrop, false);
    });

    this.inputs.forEach(element => {
      element.addEventListener('change', this.onSelect);
    });
  }

  /**
   * Unbinds all listeners.
   *
   * @memberof DropZone
   */
  unbind() {
    this.zones.forEach(element => {
      element.removeEventListener('dragover', this.onDragover, false);
      element.removeEventListener('dragenter', this.onDragenter, false);
      element.removeEventListener('dragleave', this.onDragleave, false);
      element.removeEventListener('drop', this.onDrop, false);
    });

    this.inputs.forEach(element => {
      element.removeEventListener('change', this.onSelect);
    });

    delete this.emitter;
    delete this.zones;
    delete this.inputs;
  }

  /**
   * @param {string} message Verbose error message.
   * @throws
   */
  error(message) {
    this.emitter.emit('drop-error', { message: message });
  }

  /**
   * @param  {Event} event
   */
  onSelect(event) {
    // HTML file inputs do not seem to support folders, so assume this
    // is a flat file list.
    const files = toArray(event.target.files);
    this.emitter.emit('drop', { files });

    // Clearing out file input to be able to upload duplicates(for whatever reason).
    event.target.value = '';
  }

  onDragover(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * @param {Event} event Event to handle.
   */
  onDragenter(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!isDragDataWithFiles(event)) {
      return;
    }

    event.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    this.dragStart(event);
  }

  /**
   * @param {Event} event Event to handle.
   */
  onDragleave(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!isDragDataWithFiles(event)) {
      return;
    }

    this.dragStop(event);
  }

  dragStart(event) {
    if (this.dragging === 0) {
      this.emitter.emit('drag-start', event);
    }

    this.dragging++;
  }

  dragStop(event, force = false) {
    this.dragging = force ? 0 : (this.dragging - 1);

    if (this.dragging === 0) {
      this.emitter.emit('drag-stop', event);
    }
  }

  /**
   * @param  {Event} event Drop event.
   */
  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!isDragDataWithFiles(event)) {
      return;
    }

    let files;

    if (event.dataTransfer.files.length > 0) {
      files = toArray(event.dataTransfer.files);
    } else if (event.dataTransfer.items) {
      // FIXME
      // Method `.webkitGetAsEntry` is returning other type of file, so
      // it must be converted to a corresponding object type.
      files = toArray(event.dataTransfer.items)
        .map(item => item.webkitGetAsEntry());
    }

    if (!files) {
      this.error('Required drag-and-drop APIs are not supported in this browser.');
    }

    this.dragStop(event, true);
    this.emitter.emit('drop', { files });
  }
}
