<template functional lang="pug">

include /mixins.pug

+b.TAG.loading-wrapper(
  v-bind="data.attrs"
  v-bem:loading-wrapper="{ styling: props.styling, variant: props.variant, fullscreen: props.fullscreen }"
  v-state="{ loading: props.loading }"
)
  slot
  +e.overlay
  +e.indicator
    loading(:size="props.size", :duration="props.duration")

</template>

<script>

export default {
  name: 'loading-wrapper',

  props: {
    styling: { default: 'default' },
    variant: { default: 'default' },
    fullscreen: { default: false },
    loading: { default: false },
    size: { default: 'xl' },
    duration: {},
  },
};

</script>
