<template lang="pug">

include /mixins.pug

+b.gallery-controller
  slot(
    :items="limitedItems", :toggle="toggle",
    :limited="items.length > limit",
    :opened="opened"
  )

</template>

<script>

export default {
  name: 'gallery-controller',
  props: {
    items: { type: Array, default: () => [] },
    limit: { type: Number, default: Infinity },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    limitedItems() {
      return this.items.length > this.limit && !this.opened
        ? this.items.slice(0, this.limit)
        : this.items;
    },
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
  },
};

</script>
