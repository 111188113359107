<template lang="pug">

include /mixins.pug

+b.g-row.--appearance_spaced.--space_lg(v-bind="$attrs", v-on="$listeners")
  +b.g-cell.g-cols--12(v-for="(item, i) in value", :key="i")
    +b.g-row.--appearance_nowrap.--align_baseline
      +b.g-cell.g-cols--auto
        slot(:value="item", :index="i", :input="change(i)", :remove="remove(i)", :add="add")
      +b.g-cell.g-cols--narrow
        ds-link(styling="error", @click.prevent="remove(i)()"): icon(name="close", size="2xl")
  +b.g-cell.g-cols--12(v-if="($scopedSlots.hint || hint) || (!disableAdd && ($scopedSlots.addLabel || addLabel))")
    +b.g-row.--appearance_spaced.--justify_between
      +b.g-cell.g-cols--auto
        slot(name="hint") {{ hint }}
      +b.g-cell.g-cols--12.--narrow-md
        slot(name="add", :add="add", :initial="initial", :value="value")
          ds-link(appearance="block", @click="add")
            +b.g-row.--appearance_nowrap.--space_xs
              +b.g-cell.g-cols--narrow
                icon(name="add", size="md")
              +b.g-cell.g-cols--auto
                slot(name="addLabel") {{ addLabel }}
      +b.g-cell.g-cols--narrow(style="visibility: hidden;").u-display--hidden-till-md
        ds-link(styling="error", @click.prevent="remove(i)()"): icon(name="close", size="2xl")

</template>

<script>

import { clone } from 'ramda';

export default {
  name: 'control-form-repeater',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    initial: {},
    addLabel: String,
    hint: String,
    disableAdd: Boolean,
  },

  methods: {
    change(i) {
      return value => {
        this.value[i] = value;
        this.input(this.value);
      };
    },
    remove(i) {
      return () => {
        this.input(this.value.slice(0, i).concat(this.value.slice(i + 1)));
      };
    },
    add() {
      this.input(this.value.concat([clone(this.initial)]));
    },
    input(value) {
      this.$emit('update', value);
    },
  },
};

</script>
