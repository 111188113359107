<template lang="pug">
  chat-feed-provider(:feed='feed')
    wds-chat-audio(url="https://back.files.design/media/1586", ref="messageAudio")

    slot(:feed="feed", :chatsCount="counter.count" :chat-id="chatId")
</template>

<script>
import Feed from "./DataFeed/ChatFeed";
import { messagesUnreadResource } from "./DataFeed/api";

export default {
  props: {
    reversed: {
      type: Boolean,
      default: window.USER_REVERSED,
    },
  },
  provide() {
    return {
      chatsCounter: this.counter,
      setRoomFocus: this.setRoomFocus,
    };
  },
  data() {
    return {
      feed: new Feed(),
      counter: {
        count: 0,
      },
      focusedRooms: {},
      chatId: 0
    };
  },

  created() {
    this.feed.emitter.on("message:send", this.handleNewMessage);
    this.feed.emitter.on("global:unread", this.handleChatsCount);
    this.feed.emitter.on("chat:update", this.updateRoom);
    messagesUnreadResource
      .execute()
      .then(({ count }) => (this.counter.count = count))
      .catch(() => null);
  },

  methods: {
    updateRoom(event) {
      const { body: {job: {id} }} = event
      this.chatId = id
      this.$nextTick().then(() => {
        this.chatId =  0
      })
    },
    setRoomFocus(id, value) {
      this.focusedRooms[id] = value;
    },
    handleNewMessage(event) {
      const {
        body: { roomId, isSender, authorId },
      } = event;

      if (
        !this.focusedRooms[roomId] &&
        authorId != this.USER.GET("id") &&
        isSender !== this.reversed
      ) {
        this.$refs.messageAudio && this.$refs.messageAudio.sound();
      }
    },
    handleChatsCount(event) {
      this.counter.count = event.body.count;
    },
  },
};

const data = {
  method: "message",
  body: {
    uid: "4dv1dNlhTUlr2k66CGDZRm",
    channel: "$user_channel_1",
    data: {
      message: "",
      channel: "$user_channel_1",
      event_class: "room:update",
      data: {
        id: "c914292c-5492-4a97-8a88-107e0c6a48a3",
        userFrom: {
          id: 1,
          username: "admin",
          displayName: "Marian Chorney 2008",
          avatar:
            "https://medbirzha.webcase-dev.com/uploads/b13dbdbd-91f1-4816-a9ee-98c37f712f44.jpg",
        },
        userTo: {
          id: 21,
          username: "Kate766",
          displayName: "Kate766",
          avatar: null,
        },
        job: {
          id: 30,
          title:
            "\u041c\u0435\u0434\u0441\u0435\u0441\u0442\u0440\u0430 \u0434\u043b\u044f \u0440\u0430\u0431\u043e\u0442\u044b \u0432 \u043d\u043e\u0447\u043d\u044b\u0435 \u0441\u043c\u0435\u043d\u044b",
          authorId: 21,
        },
        isStraight: false,
        unreadMessagesCount: 8,
      },
      site: "MedicalPortal",
    },
  },
};
</script>
