<template lang="html">
  <div class="g-row g-row--space_none g-row--appearance_spaced wds-chat">
    <div
      class="g-cell g-cols g-cols--12 g-cols--4-md wds-chat__list"
      :class="chat && chat.id && 'is-passive'"
    >
      <ds-panel>
        <ds-panel-element>
          <div class="wds-chat-heading wds-chat-heading--variant_lg">
            <h5
              class="ds-caption ds-caption--size_4 ds-caption--variant_medium ds-caption--color_4"
            >
              {{ _("Ваши чаты") }}
            </h5>
          </div>
        </ds-panel-element>
        <ds-panel-element>
          <wds-chat-list
            bubble
            :job-id="jobId"
            :initial="initial"
            :reversed="reversed"
            :preload-amount="40"
            :active-id="(chat && chat.id) || null"
            @input="selectChat"
          >
            <wds-chat-preview
              slot="item"
              slot-scope="chatItem"
              :variant="chatItem.chat.userTo.id === chatItem.chat.job.authorId ? 'author' : ''"
              :chat="chatItem.chat"
              :user-id="!reversed ? getChatOwner(chatItem.chat) : 0"
            />
          </wds-chat-list>
        </ds-panel-element>
      </ds-panel>
    </div>
    <div
      class="g-cell g-cols g-cols--auto wds-chat__window"
      style="display: flex; flex-direction: column;"
    >
      <template v-if="chat && chat.id">
        <div class="wds-chat-heading wds-chat-heading--variant_md">
          <wds-chat-preview
            :chat="chat"
            :user-id="!reversed ? getChatOwner(chat) : 0"
            class="wds-chat-preview--variant_room"
          >
            <template v-slot:action="">
              <a
                class="ds-link wds-chat-heading__action"
                @click="selectChat(null)"
                style="color:#4886FF"
              >
                <svg-icon name="chevron-left" size="2xl" />
              </a>
            </template>
          </wds-chat-preview>
        </div>

        <wds-chat-room
          :class="{ 'u-display--hidden-till-md': !(chat && chat.id) }"
          :user-id="getChatOwner(chat)"
          :preload-amount="40"
          height="600px"
          style="flex-grow: 1"
          :chat="chat"
        >
          <div
            class="g-row g-row--space_lg g-row--appearance_spaced"
            slot="files-preview"
            slot-scope="{ fileUpload, removeRejected }"
          >
            <div class="g-cell g-cols g-cols--12 g-cols--narrow-sm">
              <div class="ds-caption ds-caption--color_7">
                {{ _("Отправка файлов:") }}
              </div>
            </div>
            <div class="g-cell g-cols g-cols--auto">
              <div class="g-row g-row--space_xl g-row--appearance_spaced">
                <div
                  class="g-cell g-cols g-cols--narrow"
                  v-for="(file, index) in fileUpload.rejected"
                  :key="index + 10000"
                >
                  <div class="ds-panel ds-panel--space_xs">
                    <div
                      class="ds-panel__element ds-panel__element--offset_horizontal"
                    >
                      <div class="file">
                        <div class="g-row g-row--appearance_nowrap">
                          <!-- <img class="file__preview" :src="file.file.url" /> -->
                          <div class="g-cell g-cols g-cols--auto">
                            <div class="ds-caption">
                              {{ file.file.name }}
                            </div>
                            <div class="control-hint control-hint--styling_default control-hint--variant_error">
                              {{ file.file.file.error }}
                            </div>
                          </div>
                          <div class="g-cell g-cols g-cols--narrow">
                            <a
                              class="file__cancel ds-link"
                              href="javascript:void(0)"
                              @click.prevent="removeRejected(fileUpload, index)"
                            >
                              <svg-icon
                                name="close"
                                size="md"
                                style="fill: #000"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="g-cell g-cols g-cols--narrow"
                  v-for="(file, index) in fileUpload.accepted"
                  :key="index"
                >
                  <div class="ds-panel ds-panel--space_xs">
                    <div
                      class="ds-panel__element ds-panel__element--offset_horizontal"
                    >
                      <div class="file">
                        <div class="g-row g-row--appearance_spaced g-row--space_none g-row--appearance_column">
                          <!-- <img class="file__preview" :src="file.file.url" /> -->
                          <div class="g-cell g-cols g-cols--12">
                            <div class="ds-caption">
                              {{ file.file.name }}
                            </div>
                          </div>
                          <div class="g-cell g-cols g-cols--12" >
                            <div class="g-row g-row--align_center">
                              <div class="g-cell g-cols g-cols--auto" style='width: 134px'>
                                <progress-bar
                                  class="file__progress"
                                  :bars="[file.progress.relative]"
                                />
                              </div>
                              <div class="g-cell g-cols g-cols--narrow">
                                <a
                                  class="file__cancel ds-link"
                                  href="javascript:void(0)"
                                  @click.prevent="fileUpload.remove(file)"
                                >
                                  <svg-icon
                                    name="close"
                                    size="md"
                                    style="fill: #000"
                                  />
                                </a>
                              </div>
                            </div>

                            <div v-if="file.status == 'failure'">
                              <div
                                class="control-hint control-hint--styling_default control-hint--variant_error"
                                v-for="(error, i) in resolveErrors(file.error)"
                                :key="i"
                              >
                                {{ error }}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div slot="failed-messages" slot-scope="failedMessages">
            <div
              class="g-row g-row--space_xs g-row--appearance_spaced"
              v-for="message in failedMessages.messages"
            >
              <div class="g-cell g-cols g-cols--auto">
                <wds-chat-message
                  :message="message"
                  devoted="self"
                  :type="message.type"
                />
              </div>

              <div class="g-cell g-cols g-cols--narrow">
                <div class="stacked-elements stacked-elements--vertical">
                  <div class="stacked-elements__item">
                    <button
                      class="stacked-elements__element stacked-elements__element--bound_start control-button control-button--full control-button--style_default"
                      @click="failedMessages.resend(message)"
                    >
                      <icon name="send" />
                    </button>
                  </div>
                  <div class="stacked-elements__item">
                    <button
                      class="stacked-elements__element stacked-elements__element--bound_end control-button control-button--full control-button--style_default is-error"
                      @click="failedMessages.remove(message)"
                    >
                      <icon name="clear" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            slot="dropzone-cover"
            class="card"
            style="
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            opacity: 0.9;
            background-color: #eee;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 10
          "
          >
            <div class="card__panel card__panel--appearance_spaced_full">
              <div
                class="ds-caption ds-caption--size_6 ds-caption--type_secondary"
              >
                Вы что-то собираетесь грузить?
              </div>
              <div class="ds-caption ds-caption--size_4">Файлы, небось?</div>
              <div class="ds-caption ds-caption--size_1">
                <icon name="cloud_upload" />
              </div>
            </div>
          </div>
        </wds-chat-room>
      </template>

      <div
        v-else
        :class="{ 'u-display--hidden-till-md': !(chat && chat.id) }"
        class="card"
        style="
          height: 630px;
          width: 100%;
          text-align: center;
          opacity: 0.9;
          background-color: #eee;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 10
        "
      >
        <div class="card__panel card__panel--appearance_spaced_full">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LauncherMixin from "../utils/mixins/LauncherMixin.js";

import fileUploaderWrapper from "./Room/hocs/fileUploaderWrapper";
import connectionErrorHandlerWrapper from "./Room/hocs/connectionErrorHandlerWrapper";
import failedMessagesHandlerWrapper from "./Room/hocs/failedMessagesHandlerWrapper";
import feedChatListHandler from "./List/hocs/feedChatListHandler";
import feedTypingHandler from "../utils/hocs/feedTypingHandler";
import List from "./List/List";
import Preview from "./Preview/Preview";
import Room from "./Room/Room";

/**
 * Only an example of how to use chat Laucher mixin.
 *
 * - TYPE: "**offline**" to see connection lost handler. After some time
 *  you will be online again.
 * - TYPE: "**failed**" to see failed message handling example.
 */
export default {
  name: "Launcher",
  mixins: [LauncherMixin],
  props: {
    reversed: {
      type: Boolean,
      default: window.USER_REVERSED,
    },
    initial: {},
    jobId: Number,
  },
  data() {
    return {
      innerWidth: window.innerWidth
    }
  },

  computed: {
    userReceiver() {
      return this.reversed ? 'userFrom' : 'userTo';
    },
    isMobile() {
      return this.innerWidth < 768;
    },
  },

  components: {
    [Room.name]: connectionErrorHandlerWrapper(
      failedMessagesHandlerWrapper(fileUploaderWrapper(Room))
    ),
    [List.name]: feedChatListHandler(List),
    [Preview.name]: feedTypingHandler(Preview),
  },

  methods: {
    getChatOwner(chat) {
      return chat.isStraight ? this.USER.GET('id') : chat[this.userReceiver].id;
    },
    selectChat(chat) {
      this.selected = chat;
    },
    setWidth() {
      this.innerWidth = window.innerWidth
    },
    resolveErrors(error) {
      try {
        const errors = [];

        Object.values(error.response.body.errors[0].state).forEach(x => {
          x.forEach(({ message }) => {
            errors.push(message);
          });
        });

        return errors;
      } catch(e) {}

      return [this._('Undefined error during upload')];
    },
  },
  mounted() {
    window.addEventListener('resize', this.setWidth)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.setWidth)
    })
  }
};
</script>
