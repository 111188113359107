<template functional lang="pug">

include /mixins.pug

optional-validation-provider(
  :rules="props.readonly ? undefined : props.rules"
  :vid="props.vid"
  :disabled="props.disabled"
  :name="props.name"
  :skipIfEmpty="!props.required"
  :value="props.value"
  v-slot="{ errors }"
  slim
)
  +b.control-descriptor.--variant_default(
    :class="[data.staticClass, data.class]"
  )
    +e.label(v-if="props.labelText || scopedSlots.label")
      slot(name="label")
        ds-caption(size="sm") {{ props.labelText }} #[+e.SPAN.required-mark(v-if="!props.readonly && (props.rules || props.required)") *]

    +e.element
      slot(
        :disabled="props.disabled",
        :required="props.required",
        :readonly="props.readonly",
        :invalid="!!(errors && errors.length)"
      )
        tag(
          :tag="props.tag",
          :disabled="props.disabled",
          :required="props.required",
          :readonly="props.readonly",
          :invalid="!!(errors && errors.length)",
          :name="props.name",
          :value="props.value",
          v-bind="data.attrs",
          v-on="data.on"
        )

    +e.hint(v-if="props.hintText || scopedSlots.hint")
      slot(name="hint")
        +b.control-hint.--styling_default.--variant_help {{ props.hintText }}

    +e.errors(v-if="errors && errors.length")
      slot(name="errors", :errors="errors")
        +b.control-hint.--styling_default.--variant_error(
          v-for="(error, i) in errors" :key="i"
        ) {{ error }}

</template>

<script>

export default {
  name: 'control-descriptor',
};

</script>
