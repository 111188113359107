// import JobsForm from './JobsForm';
import TablesListController from './TablesListController';
import TablesTimingController from './TablesTimingController';
import TablesCalculationController from './TablesCalculationController';

function install(Vue) {
  // Vue.component(JobsForm.name, JobsForm);
  Vue.component(TablesListController.name, TablesListController);
  Vue.component(TablesCalculationController.name, TablesCalculationController);
  Vue.component(TablesTimingController.name, TablesTimingController);
}

export default { install };
