import { renderSlim } from '@aspectus/vue-utils';

export default {
  name: 'auth-provider',

  props: {
    initAction: {
      type: String,
      required: true,
    },
  },

  created() {
    this.$store.dispatch(this.initAction);
  },

  render(h) {
    const { default: c } = this.$scopedSlots;

    return c ? renderSlim(c(), h, 'tag') : h(null);
  },
};
