import { contactResource } from '@md/feedback/api';
import {
  userLoginResource, userRegisterResource, userUpdateResource,
  userPartialUpdateResource,
  passwordResetResource, passwordConfirmResource, passwordChangeResource,
  statusFilesUploadResource, statusFilesDeleteResource,
  documentFilesUploadResource, documentFilesDeleteResource,
  documentFilesChangeResource,
  coreStatusFilesUploadResource,
  coreStatusFilesDeleteResource
} from '@md/users/api';
import { filesUploadResource } from '@md/files/api';
import { jobsCreateResource, jobsUpdateResource, bidsCreateResource } from '@md/jobs/api';
import { tablesCreateResource, tablesUpdateResource, tablesDeleteResource } from '@md/tables/api';

const API = {
  files: {
    upload: filesUploadResource,
  },
  feedback: {
    contact: {
      create: contactResource,
    },
  },
  user: {
    statusFiles: {
      upload: statusFilesUploadResource,
      delete: statusFilesDeleteResource,
      uploadCoreFile: coreStatusFilesUploadResource,
      deleteCoreFile: coreStatusFilesDeleteResource,
    },
    documentFiles: {
      upload: documentFilesUploadResource,
      change: documentFilesChangeResource,
      delete: documentFilesDeleteResource,
    },
  },
  auth: {
    login: userLoginResource,
    register: userRegisterResource,
    user: {
      update: userUpdateResource,
      partialUpdate: userPartialUpdateResource,
    },
    password: {
      reset: passwordResetResource,
      confirm: passwordConfirmResource,
      change: passwordChangeResource,
    },
  },
  recruit: {
    jobs: {
      create: jobsCreateResource,
      update: jobsUpdateResource,
    },
    tables: {
      create: tablesCreateResource,
      update: tablesUpdateResource,
      delete: tablesDeleteResource,
    },
    bids: {
      create: bidsCreateResource,
    },
  },
};

function install(Vue) {
  Vue.prototype.$api = API;
}

export default { install };
