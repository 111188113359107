const lessThen10Shift = val => val < 10 ? '0' + val : val

export function minutesToString(hours, minutes) {
  const sign = (minutes < 0 || hours < 0) ? '-' : '';

  hours = Math.abs(hours);
  minutes = Math.abs(minutes);

  return `${sign}${lessThen10Shift(hours)}:${lessThen10Shift(minutes)}`;
}

export function minuteToString(minutes) {
  return minutesToString(parseInt(minutes / 60), minutes % 60);
}

export function stringToMinutes(string) {
  let fac = 1;

  if (string[0] === '-') {
    fac = -1;
    string = string.slice(1);
  }

  return string.split(":").reduce((acc, time) => {
    return 60 * acc + +time
  }, 0) * fac;
}


const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate()

export const generateMonth = (month, year, {
  startTime = '09:00',
  endTime = '18:00',
  restTime = '00:00',
} = {}) => {
  const days = getDaysInMonth(month, year)
  const shiftedMonth = lessThen10Shift(month)

  return new Array(days).fill({}).map((day, index) => {
    return {
      startTime,
      endTime,
      restTime,
      startDate: `${lessThen10Shift(index + 1)}.${shiftedMonth}.${year}`,
      endDate: `${lessThen10Shift(index + 1)}.${shiftedMonth}.${year}`,
      workTime: '',
    };
  });
};
