const LOADING_KEY = 'loading_ad390sd0gsdf9sf';

export default {
  data() {
    return {
      // Dunno why, but vue do not recognize `$`(dollar sign).
      // Because loading property is internal it's not a problem to add
      // some weird key to it.
      [LOADING_KEY]: 0,
      loading: false,
    };
  },

  methods: {
    startLoading() {
      if (this[LOADING_KEY] === 0) {
        this.loading = true;
        this.$emit('loadingStarted');
      }

      this[LOADING_KEY]++;
    },

    $load(promise) {
      this.startLoading();
      return promise.finally(result => {
        this.stopLoading();
        return result;
      });
    },

    stopLoading() {
      this[LOADING_KEY]--;

      if (this[LOADING_KEY] === 0) {
        this.loading = false;
        this.$emit('loadingFinished');
      }
    }
  }
};
