import Select from './Select';
import Button from './Button';
import CheckerSelect from './CheckerSelect';
import Input from './Input';
import AddressInput from './AddressInput';
import TextArea from './TextArea';
// import Info from './Info';
import Badge from './Badge';
// import Dropdown from './Dropdown';
import Checker from './Checker';
import File from './File';

import Checkbox from './Checkbox';
import Radio from './Radio';
import CheckerWrapper from './CheckerWrapper';
import ControlStack from './ControlStack';

import DatePicker from './DatePicker';
import DateRangePicker from './DateRangePicker';
import TimeRangePicker from './TimeRangePicker';
import NumberRangePicker from './NumberRangePicker';
import Descriptor from './Descriptor';
import OptionalValidationProvider from './OptionalValidationProvider';
import FormRepeater from './FormRepeater';

import { DAddressInput, DInput, DTextArea, DFile, DSelect, DDatePicker } from './described';

function install(Vue) {
  Vue.component(Select.name, Select);
  Vue.component(CheckerSelect.name, CheckerSelect);
  Vue.component(Input.name, Input);
  Vue.component(AddressInput.name, AddressInput);
  Vue.component(TextArea.name, TextArea);
  Vue.component(Button.name, Button);
  // Vue.component(Info.name, Info);
  Vue.component(Badge.name, Badge);
  // Vue.component(Dropdown.name, Dropdown);
  Vue.component(Checker.name, Checker);
  Vue.component(Checkbox.name, Checkbox);
  Vue.component(Radio.name, Radio);
  Vue.component(CheckerWrapper.name, CheckerWrapper);
  Vue.component(ControlStack.name, ControlStack);
  Vue.component(File.name, File);

  Vue.component(DatePicker.name, DatePicker);
  Vue.component(DateRangePicker.name, DateRangePicker);
  Vue.component(TimeRangePicker.name, TimeRangePicker);
  Vue.component(NumberRangePicker.name, NumberRangePicker);
  Vue.component(Descriptor.name, Descriptor);
  Vue.component(OptionalValidationProvider.name, OptionalValidationProvider);
  Vue.component(FormRepeater.name, FormRepeater);

  Vue.component('d-input', DInput);
  Vue.component('d-address-input', DAddressInput);
  Vue.component('d-file', DFile);
  Vue.component('d-select', DSelect);
  Vue.component('d-text-area', DTextArea);
  Vue.component('d-date-picker', DDatePicker);
}

export default {
  install,
};
